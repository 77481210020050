import { ADD_THEME } from "../constants/ActionTypes";
import { ADD_STELLAR_URL, ADD_LOADING_MESSAGE } from "../constants/ActionTypes";
import {
  niftronUserLambda,
  niftronTokenLambda,
  StellarUrl,
  StellarUrlTest,
  Version,
  NiftronIssuerPublicKey,
  NiftronDistributorPublicKey,
} from "../../variables/constants";

const initialState = {
  isDarkMode: false,
  niftronUserLambda,
  niftronTokenLambda,
  StellarUrl,
  StellarUrlTest,
  Version,
  NiftronIssuerPublicKey,
  NiftronDistributorPublicKey,
  LoadingMessage: "please remain patient",
};

export default function variables(state = initialState, action) {
  switch (action.type) {
    case ADD_THEME:
      state.isDarkMode = action.boolean;
      return state;
    default:
      return state;
  }
}
