import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import ViewHeader from "../ViewHeader";
import { Button } from "@mui/material";
import { CustomTextField, Label } from "../../../CustomTextField";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useStateValue } from "../../../../context/StateProvider";
import { ColumnContainer, CustomTextFieldForm } from "./CustomTextFieldForm";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import { Blockchain, NIFTRON } from "niftron-client-sdk";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { creatorContracts } from "../../../../variables/constants";
import { AddImageToIPFS } from "../../../../services/IpfsService";
import { getUserSession } from "../../../../services/UserManagement";
import jwt from "jsonwebtoken";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Text } from "../../../../Typography/Typography";
import CancelIcon from "@mui/icons-material/Cancel";

const nftSelectOptions = [
  { value: "NFT", label: "NFT" },
  { value: "SFT", label: "SFT" },
];
const nftSelectOptions2 = [{ value: "NFT", label: "NFT" }];

const blockchainSelectOptions = [
  // { value: "STELLAR", label: "STELLAR" },
  { value: "ETHEREUM", label: "ETHEREUM" },
  { value: "BSC", label: "BSC" },
  { value: "MATIC", label: "MATIC" },
  { value: "RINKEBY", label: "RINKEBY" },
  { value: "BSCTESTNET", label: "BSCTESTNET" },
  { value: "MUMBAI", label: "MUMBAI" },
];

const blockchainSelectOptions2 = [{ value: "STELLAR", label: "STELLAR" }];

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    background: theme.palette.text.button,
    borderRadius: "5px",
    color: theme.palette.text.text,
    padding: "0.6rem 1rem",
    textTransform: "inherit",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#afcef7",
    },
    "&:disabled": {
      color: "#8c8c8c",
    },
  },
}));

export const CustomCreateForm = (props) => {
  const history = useHistory();
  const isDarkMode = useSelector((state) => state.isDarkMode);

  var deleteObjects = [
    "itemName",
    "nftSelect",
    "addedDate",
    "blockchain",
    "supplyLimit",
    "description",
    "images",
  ];
  const styles = useStyles();
  const [files, setFiles] = useState([]);
  const [formStatus, setFormStatus] = useState();
  const [formDataState, setFormDataState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("please remain patient...");
  const [blockchain, setBlockchain] = useState({
    label: "MATIC",
    value: "MATIC",
  });
  const [nftSelect, setNftSelect] = useState({ label: "NFT", value: "NFT" });

  const [itemName, setItemName] = useState();
  const [supplyLimit, setSupplyLimit] = useState("1");
  const [description, setDescription] = useState();
  const [user, setUser] = useState(getUserSession());

  const [{ fields, products }, dispatch] = useStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.type,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
      padding: "0.1rem 0.3rem",
      background: isDarkMode ? "#fff" : "#80b7ff88",
    }),
  };



  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img alt="thumbnail" src={file.preview} style={img} />
      </div>
    </div>
  ));

  function handlePreview(e) {
    e.preventDefault();
    // var empty = document.forms["main-form"]["dropzoneImage"].value;
    if (files.length === 0) {
      enqueueSnackbar(`Please input an image!`, {
        variant: "error",
      });
      return false;
    }
    setFormStatus("preview");
    setFormDataState(getFormData(e.currentTarget));
  }

  const dispatchProduct = async (e) => {
    console.log(formDataState);
    try {
      dispatch({
        type: "ADD_PRODUCT",
        item: formDataState,
      });
      dispatch({
        type: "CLEAR_FIELDS",
      });
    } catch (error) {
      console.log(error);
    } finally {
      enqueueSnackbar(`Token Added`, {
        variant: "success",
      });
    }
  };

  function handleEdit(e) {
    e.preventDefault();
    setFormStatus("");
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const ERC721ABI = require("./ERC721.json");

      const value = formDataState;
      console.log(value)
      // const reader = new FileReader();
      // reader.readAsDataURL(files[0]);

      let contractId = null;
      switch (value.blockchain) {
        case Blockchain.STELLAR:
          contractId = null;
          break;
        case Blockchain.BSCTESTNET:
          contractId = creatorContracts.BSCTESTNET.CREATOR;
          break;
        case Blockchain.BSC:
          contractId = creatorContracts.BSC.CREATOR;
          break;
        case Blockchain.MATIC:
          contractId = creatorContracts.MATIC.CREATOR;
          break;
        case Blockchain.MUMBAI:
          contractId = creatorContracts.MUMBAI.CREATOR;
          break;
        case Blockchain.ETHEREUM:
          contractId = creatorContracts.ETHEREUM.CREATOR;
          break;
        case Blockchain.RINKEBY:
          contractId = creatorContracts.RINKEBY.CREATOR;
          break;
        default:
          contractId = null;
      }

      const tokenConfig = {
        tokenName: value.itemName,
        tokenType: value.nftSelect,
        tokenData: value.description,
        tokenCount: value.nftSelect === "NFT" ? 1 : parseInt(value.supplyLimit),
        previewImageUrl: "",
        blockchain: value.blockchain,
        creatorPublicKey: user.publicKey,
          // user && value.blockchain === "STELLAR" ? user.publicKey : null,
        contract: value.blockchain !== "STELLAR" ? ERC721ABI : null,
        contractId: value.blockchain !== "STELLAR" ? contractId : null,
        customData
      };

      setLoadingMessage("uploading image to ipfs...")
      const ipfsData = await AddImageToIPFS(files[0]);
      tokenConfig.previewImageUrl = `https://cloudflare-ipfs.com/ipfs/${ipfsData.ipfsHash}`;
      try {
        setLoadingMessage("building transaction...")
        setTimeout(function () {

          setLoadingMessage("executing transaction...")
        }, 5000);

        setTimeout(function () {
          setLoadingMessage("submitting token to niftron store...")
        }, 7000);
        const niftronRes = await NIFTRON.tokenBuilder.createToken(
          tokenConfig,
          {
            transferable: true,
          },
          value.blockchain !== "STELLAR" ? user.accounts[2].publicKey : null
        );
        dispatchProduct(value);
        console.log(niftronRes);
        dispatch({
          type: "CLEAR_FIELDS",
        });
        setFiles([]);
        history.push("/vault");
      } catch (er) {
        console.log(er)
        enqueueSnackbar(er.message, {
          variant: "error",
        });
      }
    } catch (e) {
      enqueueSnackbar(`Token creation failed`, {
        variant: "error",
      });
    }
    setLoading(false);
    
  }

  var removeObjectProperties = function (obj, props) {
    var customData = JSON.parse(JSON.stringify(obj));
    for (var i = 0; i < props.length; i++) {
      if (customData.hasOwnProperty(props[i])) {
        delete customData[props[i]];
      }
    }
    return customData;
  };

  //FormData
  const getFormData = (target) => {
    var currentDateTime = moment().format("lll");
    var tokenObject = {};
    const formData = new FormData(target);
    formData.append("addedDate", currentDateTime);
    formData.append("blockchain", blockchain.value);
    formData.append("nftSelect", nftSelect.value);
    formData.append("images", files);

    for (let [key, value] of formData.entries()) {
      tokenObject[key] = value;
    }
    return tokenObject;
  };

  // console.log(formDataState.itemName);
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  var customData = removeObjectProperties(formDataState, deleteObjects);
  const propertyNames = Object.keys(customData);

  const dropzoneThumbs = files.map((file) => (
    <div style={dropzoneThumb} key={file.name}>
      <CancelIcon
        onClick={() => setFiles([])}
        style={{
          position: "absolute",
          top: "0%",
          left: "100%",
          zIndex: "99",
          transform: "translate(-50%, -50%)",
          color: "#8a8888",
        }}
      />
      <div style={dropzoneThumbInner}>
        <img alt="preview" src={file.preview} style={dropzoneImg} />
      </div>
    </div>
  ));

  return (() => {
    switch (formStatus) {
      case "preview":
        return (
          <Container>
            {loading && (
              <SpinnerBox style={{ width: "100%", height: "30rem" }}>
                <CircularProgress />
                <Label style={{ marginBottom: "0.3rem" }}>
                  {loadingMessage}
                </Label>
              </SpinnerBox>
            )}
            {!loading && (
              <>
                <ViewHeader
                  heading="Token Preview"
                  description="View your custom token below."
                />
                <GridContainer style={{ marginTop: "2rem" }}>
                  <ColumnContainer style={{ gap: "1rem" }}>
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Token Name
                      </Label>
                      <DisabledView style={{ textTransform: "capitalize" }}>
                        {formDataState.itemName}
                      </DisabledView>
                    </ColumnContainer>
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Description
                      </Label>
                      <DisabledView>{formDataState.description}</DisabledView>
                    </ColumnContainer>
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Supply Limit
                      </Label>
                      <DisabledView>{supplyLimit}</DisabledView>
                    </ColumnContainer>
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Blockchain
                      </Label>
                      <DisabledView style={{ textTransform: "uppercase" }}>
                        {formDataState.blockchain}
                      </DisabledView>
                    </ColumnContainer>
                    <ColumnContainer>
                      <Label style={{ marginBottom: "0.3rem" }}>
                        Token Type
                      </Label>
                      <DisabledView style={{ textTransform: "uppercase" }}>
                        {formDataState.nftSelect}
                      </DisabledView>
                    </ColumnContainer>
                    {propertyNames.map((data) => (
                      <ColumnContainer key={data.key}>
                        <Label style={{ marginBottom: "0.3rem" }}>{data}</Label>
                        <DisabledView>{customData[data]}</DisabledView>
                      </ColumnContainer>
                    ))}
                  </ColumnContainer>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                </GridContainer>
                <ButtonContainer>
                  <Button
                    form="main-form"
                    className={styles.primaryButton}
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={handleEdit}
                    disabled={loading}
                  >
                    Edit Form
                  </Button>
                  <Button
                    form="main-form"
                    className={styles.primaryButton}
                    endIcon={<KeyboardArrowRightIcon />}
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </ButtonContainer>
              </>
            )}
          </Container>
        );
      default:
        return (
          <Container>
            <ViewHeader
              heading="Token"
              description="Create your custom token below."
            />
            <FormContainer
              name="main-form"
              id="main-form"
              onSubmit={handlePreview}
            >
              <CustomTextField
                label="Item Name"
                value={itemName}
                onChange={setItemName}
                required
                placeholder="Item Name"
                id="itemName"
                name="itemName"
                type="text"
              />
              <CustomTextField
                label="Description"
                value={description}
                onChange={setDescription}
                required
                placeholder="Description"
                id="description"
                name="description"
                type="text"
              />
              <GridContainer>
                <ColumnContainer>
                  <Label style={{ marginBottom: "0.3rem" }}>Blockchain</Label>
                  <Select
                    // options={
                    //   nftSelect.label === "SFT"
                    //     ? blockchainSelectOptions2
                    //     : blockchainSelectOptions
                    // }
                    options={blockchainSelectOptions}
                    styles={customStyles}
                    value={blockchain}
                    onChange={setBlockchain}
                    required
                    defaultValue={{ label: "STELLAR", value: "STELLAR" }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Label style={{ marginBottom: "0.3rem" }}>Token Type</Label>
                  <Select
                    // disabled={blockchain.label != "STELLAR"}
                    options={
                      supplyLimit > "1"
                        ? nftSelectOptions
                        : nftSelectOptions2
                    }
                    styles={customStyles}
                    value={nftSelect}
                    onChange={(val) => {

                      setNftSelect(val)
                      if (val.value === "NFT") {
                        setSupplyLimit("1")
                      }

                    }}
                    required
                    defaultValue={{ label: "NFT", value: "NFT" }}
                  />
                </ColumnContainer>
              </GridContainer>
              <ColumnContainer style={{ gridRow: "span 2" }}>
                <Label style={{ marginBottom: "0.3rem" }}>Image</Label>
                {/* <DropZoneComponent
                  files={files}
                  id="dropzone"
                  setFiles={setFiles}
                  type="image/jpeg, image/png"
                  description="Select or Drop an Image"
                /> */}
                <DroppableContainer>
                  <InnerContainer {...getRootProps()}>
                    <input
                      id="dropzoneImage"
                      type="image/jpeg, image/png"
                      {...getInputProps()}
                    />
                    {files.length === 0 && (
                      <Text primary lighter small>
                        Select or Drop an Image
                      </Text>
                    )}
                    <aside style={dropzoneThumbsContainer}>
                      {dropzoneThumbs}
                    </aside>
                  </InnerContainer>
                </DroppableContainer>
              </ColumnContainer>
              <ColumnContainer>
                {/* {nftSelect && nftSelect.value === "SFT" && ( */}
                <CustomTextField
                  label="Supply limit"
                  value={supplyLimit}
                  onChange={(supply) => {
                    setSupplyLimit(supply)
                    if (supply > "1") {
                      setNftSelect({ label: "SFT", value: "SFT" })
                    } else if (supply === "1") {
                      setNftSelect({ label: "NFT", value: "NFT" })
                    } else{
                      setSupplyLimit("1")

                    }

                  }}
                  required
                  placeholder="Supply limit"
                  id="supplyLimit"
                  name="supplyLimit"
                  type="number"
                  pattern="[0-9]{0,5}"
                />
                {/* )} */}
              </ColumnContainer>
              {fields &&
                fields.map((item, key) => {
                  return (
                    <CustomTextField
                      custom
                      form="main-form"
                      key={key}
                      label={item.name}
                      placeholder={item.name}
                      id={item.name}
                      required
                      name={item.name.replace(/ /g, "")}
                      pattern={item.select.value === "number" && "[0-9]{0,5}"}
                      type={item.select.value}
                    />
                  );
                })}
            </FormContainer>
            <CustomTextFieldForm />
            <ButtonContainer>
              <Button
                form="main-form"
                className={styles.primaryButton}
                endIcon={<KeyboardArrowRightIcon />}
                type="submit"
              >
                Create Token
              </Button>
            </ButtonContainer>
          </Container>
        );
    }
  })();
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const FormContainer = styled.form`
  width: 100%;
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  row-gap: 1rem;
  justify-content: center;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0.5rem;
  gap: 1rem;
`;

export const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  flexWrap: "wrap",
};

export const thumb = {
  display: "inline-flex",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 300,
  boxSizing: "border-box",
};

export const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

export const img = {
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  height: "30rem",
  // height: "auto",
};

export const DisabledView = styled.div`
  display: flex;
  color: #2c2858;
  padding: 0.5rem;
  background-color: #dae9f7;
  border-radius: 5px;
  align-items: center;
  word-break: break-all;
`;

const SpinnerBox = styled.div`
  width: 100%;
  background-color: #f0f8ff18;
  height: 210px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;

const InnerContainer = styled.div`
  height: 8rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #ae9bf0a3;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e147;
`;

const dropzoneThumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const dropzoneThumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #EAEAEA",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
};

const dropzoneThumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const dropzoneImg = {
  display: "block",
  width: "auto",
  height: "100%",
};
