import React, { useState } from "react";
import { Route, Switch, useLocation } from "react-router";
import { Container } from "./CreatorDashboard";
import Tab from "./Tab/Tab";
import TabView from "./Tab/TabView";
import Create from "./tabViews/Create/Create";
import CreateSwitch from "./tabViews/Create/CreateSwitch";

//Set Tabs
var tabOne = "Create";

export default function CreatorCreate() {
  return (
    <Container>
      <Tab tabOne={tabOne} />
      <TabView
        component={
          <Switch>
            <Route path="/create" component={Create} exact />
            <Route path="/create/:view" component={CreateSwitch} />
          </Switch>
        }
      />
    </Container>
  );
}
