import React from "react";
import styled from "styled-components";
import ViewHeader from "../ViewHeader";
import CreateCard from "./CreateCard";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HomeWorkIcon from "@mui/icons-material/HomeWork";

export default function Create() {
  return (
    <Container>
      <ViewHeader
        heading="Choose a Realm"
        description="Picking the correct realm will further enhance the usability of your blockchain usecase."
      />
      <GridContainer>
        <CreateCard
          pathname="/create/digital"
          title="Digital"
          icon={
            <MonetizationOnIcon
              style={{
                backgroundColor: "#4a4b96",
                borderRadius: "50%",
                padding: "5px",
                fontSize: "48px",
              }}
              fontSize="inherit"
              color="primary"
            />
          }
          description="This will allow the creation of Digital NFTs."
        />
        <CreateCard
          pathname="/create/physical"
          title="Physical"
          icon={
            <HomeWorkIcon
              style={{
                backgroundColor: "#4a4b96",
                borderRadius: "50%",
                padding: "5px",
                fontSize: "48px",
              }}
              fontSize="inherit"
              color="primary"
            />
          }
          description="This will allow the creation of Physical NFTs."
        />
      </GridContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: 3rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
