import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
//Success
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useStateValue } from "../../../../context/StateProvider";
import { CustomTextField, Label } from "../../../CustomTextField";
import { useSelector } from "react-redux";

const options = [
  { value: "number", label: "Number" },
  { value: "text", label: "Text" },
];

export const CustomTextFieldForm = () => {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  const { enqueueSnackbar } = useSnackbar();
  const [{ fields }, dispatch] = useStateValue();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [select, setSelect] = useState({
    label: "Text",
    value: "text",
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
      padding: "0.1rem 0.3rem",
      background: isDarkMode ? "#fff" : "#80b7ff88",
    }),
  };

  useEffect(() => {
    if (name === "") {
      setNameError(true);
    } else setNameError(false);
  }, [name]);

  const handleFieldSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch({
        type: "ADD_FIELD",
        item: {
          name: name,
          select: select,
        },
      });
      enqueueSnackbar(`Field Added ${name}`, {
        variant: "success",
      });
      setName("");
      setSelect({
        label: "Text",
        value: "text",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ColumnContainer>
      <Label style={{ marginBottom: "0.5rem", marginTop: "2rem" }}>
        Add Custom Field
      </Label>
      <Container id="custom-form">
        <GridContainer>
          <CustomTextField
            label="Enter Field Name"
            placeholder="Enter Field Name"
            id="field-name"
            name="field-name"
            required
            value={name}
            onChange={setName}
            type="text"
          />
          <ColumnContainer>
            <Label style={{ marginBottom: "0.3rem" }}>Select Data Type</Label>
            <Select
              options={options}
              styles={customStyles}
              value={select}
              onChange={setSelect}
              required
              defaultValue={{ label: "Integer", value: "number" }}
            />
          </ColumnContainer>
        </GridContainer>
        <ButtonWrapper>
          <Button
            style={{
              background: "#80B7FF",
              color: "#2C2858",
              textTransform: "inherit",
              fontWeight: "500",
              fontFamily: "Poppins",
              padding: ".3rem 1rem",
              float: "right",
            }}
            onClick={handleFieldSubmit}
            disabled={nameError}
            type="submit"
            size="small"
          >
            Add field
          </Button>
        </ButtonWrapper>
      </Container>
    </ColumnContainer>
  );
};



const Container = styled.form`
  background-color: ${(props) => props.theme.colors.switchTab};
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
`;

const GridContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonWrapper = styled.div`
  width: auto;
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
