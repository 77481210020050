export const light = {
  palette: {
    text: {
      default: "#120b31",
      button: "#120b31",
      text: "#fff",
      icon: "#120b31",
    },
    table: {
      header: "#120b31",
    },
    textColor: "#000000",
    overrides: {
      MuiInput: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "none",
        },
      },
    },
    primary: {
      main: "#006eff",
    },
    secondary: {
      main: "#120b31",
    },
    textField: {
      main: "#47474740",
    },
    type: "light",
  },
  overrides: {
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "gray",
        },
        color: "#11093e",
      },
    },
  },
  typography: {
    fontFamily: ["Poppins"].join(","),
  },
};
