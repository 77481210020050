import React from "react";
import styled from "styled-components";
import { Description, NavHeading } from "../../../../../Typography/Typography";

export const SwitchTab = (props) => {
  return (
    <Container onClick={props.onClick} active={props.active}>
      <NavHeading>{props.heading}</NavHeading>
      <Description>{props.description}</Description>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  gap: 0.5rem;
  display: flex;
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.switchTabActive
      : props.theme.colors.switchTab};
  border-radius: 5px;
  padding: 1rem;
  cursor: pointer;
  flex-direction: column;
`;
