import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Paragraph, TinyText, ButtonSmallText, PrimarySubText, Amount } from "../../Typography/Typography";

//MUI
import { Button, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import jwt from "jsonwebtoken";
import CopyAll from "@mui/icons-material/ContentCopy";
import CopyPasteComponent  from "../CopyPasteComponent";
import { NIFTRON } from "niftron-client-sdk";

const useStyles = makeStyles(() => ({
  divider: {
    width: "90%",
    background: "#dddddd2d",
  },
  primaryButton: {
    background: "#80B7FF",
    color: "#11093E",
    height: 48,
    width: "40%",
    padding: "0 30px",
    textTransform: "inherit",
    fontSize: "16px",

    "&:hover": {
      backgroundColor: "#afcef7",
    },
  },
}));

export default function PopUp(props) {
  const classes = useStyles();
  const [user, setUser] = useState(null);

  const logout = () => {
    NIFTRON.user.logout();
  };

  useEffect(() => {
    const getUser = () => {
      if (localStorage.getItem("niftoken")) {
        const token = localStorage.getItem("niftoken");
        if (token != null) {
          const decodedToken = jwt.decode(token);
          if (decodedToken != null) {
            setUser(decodedToken);
          }
        }
      }
    };
    getUser();
  }, []);
  //popup

  return (
    <Container onMouseOut={(e) => {
      e.preventDefault()
      props.setShowPopUp(false)
    }}
      className={`${props.show ? "hidePopUp-before" : "hidePopUp-after"}`}
    >
      {user && (
        <div style={{ width: "100%" }}>
          <AccountsContainer>
            {/* <ProfilePicWrapper>
              <ProfilePic
                // src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                alt="Profile Picture"
              />
            </ProfilePicWrapper> */}

            <div>
              <CopyPasteComponent value={user.alias} label={`Alias`}/>
              <CopyPasteComponent value={user.publicKey} label={`Stellar PublicKey`}/>
              <CopyPasteComponent value={user.accounts.length >= 3 ? user.accounts[2].publicKey: ""} label={`EVM Address`}/>

              {/* 
              <Paragraph style={{ textAlign: "center" }}>
                {user.alias}    <CopyAll />
              </Paragraph>
              <PrimarySubText style={{ textAlign: "center" }}>
                {user.alias}    <CopyAll />
              </PrimarySubText> */}
              {/* <TinyText>{user.email}</TinyText> */}

            </div>
          </AccountsContainer>
          {/* <MupltipleAccountWrapper>
          <AccountDetails
            profilePic="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
            name="John Doe"
            email="johndoe@gmail.com"
          />
          <AccountDetails
            profilePic="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
            name="John Doe"
            email="johndoe@gmail.com"
          />{" "}
          <AccountDetails
            profilePic="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
            name="John Doe"
            email="johndoe@gmail.com"
          />{" "}
          <AccountDetails
            profilePic="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
            name="John Doe"
            email="johndoe@gmail.com"
          />
        </MupltipleAccountWrapper> */}
        </div>
      )}

      {/* <Divider className={classes.divider} orientation="horizontal" /> */}
      <Button className={classes.primaryButton} onClick={logout}>
        Log out
      </Button>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: ${(props) => props.theme.colors.authHeaderShadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-width: 22rem;
  padding: 2rem 0;
  border-left: 0.2px solid ${(props) => props.theme.colors.authBorder};
  transition: all ease-in-out 300ms;
  padding: 3rem 1rem;
  @media (max-height: 700px) {
    padding: 1rem 1rem;
  }
`;

const ProfilePic = styled.img`
  width: 8rem;
  height: auto;
  cursor: pointer;
`;

const ProfilePicWrapper = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 8rem;
  height: 8rem;
`;

const AccountsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1rem;
`;

const MultipleAccountWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1rem;
  @media (max-height: 700px) {
    margin-top: 1rem;
  }
`;
