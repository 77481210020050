import React, { useState } from "react";
import { Container } from "./CreatorDashboard";
import Tab from "./Tab/Tab";
import TabView from "./Tab/TabView";
import MyCreations from "./tabViews/MyCreations/MyCreations";

//Set Tabs
var tabOne = "My Vault";

export default function CreatorCreations() {
  const [view, setView] = useState(tabOne);

  return (
    <Container>
      <Tab tabOne={tabOne} setActive={setView} reuse />
      <TabView component={<MyCreations />} />
    </Container>
  );
}
