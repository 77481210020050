import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStateValue } from "../../../../../context/StateProvider";
import { CustomTextField, Label } from "../../../../CustomTextField";
import {
  ColumnContainer,
  CustomTextFieldForm,
} from "../../Create/CustomTextFieldForm";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button } from "@mui/material";
import { ButtonContainer } from "../../Create/CustomCreateForm";
import { useSnackbar } from "notistack";
import Select from "react-select";
import DropZoneComponent from "../../Create/DropZoneComponent";
import {
  Blockchain,
  NIFTRON,
  approvalPopUp,
  NiftronKeypair,
} from "niftron-client-sdk";
import { getUserSession } from "../../../../../services/UserManagement";
import { useSelector } from "react-redux";
import PickMultipleUser from "./PickMultipleUser"
import PickUser from "./PickUser"

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LinearProgress } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useHistory, useLocation } from "react-router";


const recipientsSelectOptions = [
  { value: "NFT", label: "Random Name" },
  { value: "SFT", label: "Not Random Name" },
];

const approversSelectOptions = [
  { value: "NFT", label: "Random Name" },
  { value: "SFT", label: "Not Random Name" },
];

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    background: theme.palette.text.button,
    borderRadius: "5px",
    color: theme.palette.text.text,
    padding: "0.6rem 1rem",
    textTransform: "inherit",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#afcef7",
    },
    "&:disabled": {
      backgroundColor: "#120b31ab",
      color: "#ffffff",
    },
  },
}));

const TransferCertificate = (props) => {
  const history = useHistory();

  const styles = useStyles();
  const isDarkMode = useSelector((state) => state.isDarkMode);

  const [files, setFiles] = useState([]);
  const [receiver, setReceiver] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState(getUserSession());

  const [{ fields, products }, dispatch] = useStateValue();
  const [recipientsSelect, setRecipientSelect] = useState({
    label: "Select recipient Name",
    value: null,
  });


  const [approversSelect, setApproversSelect] = useState({
    label: "Select Approvers",
    value: null,
  });

  const [receivers, setReceivers] = useState([]);
  const [proceedToVault, setProceedToVault] = useState(false);

  const [transferStatesArray, setTransferStatesArray] = useState([])
  const [transferStates, setTransferStates] = useState({})

  const addLoadingmessage = (receiver, message, loading, success, failed) => {
    let data = transferStates
    data[receiver] = { message, loading, success, failed }
    setTransferStates(data)

    var array = []
    for (var property in data) {
      if (data.hasOwnProperty(property)) {
        array.push({
          receiver: property,
          message: data[property].message,
          loading: data[property].loading,
          success: data[property].success,
          failed: data[property].failed,

        })
      }
    }
    setTransferStatesArray(array)
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
      padding: "0.1rem 0.3rem",
      background: isDarkMode ? "#fff" : "#80b7ff88",
    }),
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //   dispatch({
      //     type: "ADD_PRODUCT",
      //     item: formDataState,
      //   });
      console.log(props.token);
      switch (props.token.blockchain) {
        case Blockchain.STELLAR:
          //should implement for bulk
          await NIFTRON.tokenBuilder.transferToken(
            receiver,
            props.token.assetCode,
            props.token.assetIssuer,
            1,
            user.publicKey
          );
          break;
        default:
          try {
            if (receivers) {
              if (receivers.length > props.availableTokenIds.length) {
                enqueueSnackbar(`Not enough tokens to issue all the receipients`, {
                  variant: "warning",
                });
                return;
              }

              const secretKey = await NIFTRON.tokenBuilder.approvalPopUp()
              setOpen(true);
              for (let i = 0; i < receivers.length; i++) {
                if (receivers[i].accounts.length > 2) {
                  // handleClickOpen('paper')

                  addLoadingmessage(receivers[i].alias, "Executing Transfer Txn", true, false, false)

                  await NIFTRON.tokenBuilder.evmTransferToken(
                    props.token.blockchain,
                    receivers[i].accounts[2].publicKey,
                    props.availableTokenIds[i],
                    props.token.contractId,
                    props.token.contract,
                    user.publicKey,
                    secretKey
                  );
                  // handleClose()
                  addLoadingmessage(receivers[i].alias, `TokenID: ${props.availableTokenIds[i]} issued`, false, true, false)

                } else {
                  // handleClose()


                  addLoadingmessage(receivers[i].alias,
                    `Recipient account is incompatible`, false, false, true)

                  enqueueSnackbar(`Some recipient accounts are incompatible`, {
                    variant: "warning",
                  });
                  return
                }
              }
            }
            setOpen(true);
            enqueueSnackbar(`Token Issued`, {
              variant: "success",
            });
            setProceedToVault(true)
          } catch (e) {
            setOpen(false);

            enqueueSnackbar(e, {
              variant: "warning",
            });
            console.log(e)
          }
      }

      dispatch({
        type: "CLEAR_FIELDS",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {


  }, [files])
  return (
    <Container style={{ gap: "1rem" }} id="issue-form" onSubmit={handleSubmit}>
      <ColumnContainer>
        <Label style={{ marginBottom: "0.3rem" }}>{props.token.tokenType === "NFT" ? "Select Recipient" : "Select Recipients"}</Label>
        {/* <Select
          options={recipientsSelectOptions}
          styles={customStyles}
          value={recipientsSelect}
          onChange={setRecipientSelect}
          required
        /> */}
        {/* {props.token.tokenType === "NFT" ?
          <PickUser setValue={setReceiver} blockchain={props.token.blockchain} availableTokenIds={props.availableTokenIds}/>
          : */}
        <PickMultipleUser setValue={setReceivers} receivers={receivers} availableTokenIds={props.availableTokenIds} />
        {/* } */}

        {/* 
        <Label
          style={{
            marginBottom: "0.3rem",
            marginTop: "0.3rem",
            textAlign: "center",
          }}
        >
          or
        </Label>
        <DropZoneComponent
          setFiles={setFiles}
          type="text/csv"
          name="Add a CSV file"
          description="Select or Drop a CSV with the following field: alias"
        /> */}
      </ColumnContainer>
      {/* <ColumnContainer>
        <Label style={{ marginBottom: "0.3rem" }}>Select Approvers</Label>
        <Select
          options={approversSelectOptions}
          styles={customStyles}
          value={approversSelect}
          onChange={setApproversSelect}
          required
        />
      </ColumnContainer> */}
      {/* <CustomTextField
        label="Number for each approver"
        required
        placeholder="Number for each approver"
        id="NumberForEachApprover"
        name="NumberForEachApprover"
        type="number"
        pattern="[0-9]{0,5}"
      /> */}
      {/* <CustomTextField
        label="Receiver Address"
        required
        placeholder="Enter Receiver Public Key"
        id="receiver"
        name="receiver"
        value={receiver}
        onChange={setReceiver}
        type="text"
      // pattern="[0-9]{0,5}"
      /> */}
      {/* <Button onClick={handleClickOpen('paper')}>scroll=paper</Button> */}
      <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
        // onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Issue Status</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <List component="div" role="group">
              {transferStatesArray.map((item, key) => {
                return (
                  <>
                    {/* {item.loading && <LinearProgress />} */}
                    <ListItem divider key={key}>
                      <ListItemText primary={item.receiver.toUpperCase()} secondary={item.message} />
                      {item.loading && <CircularProgress />}
                      {item.success && <ThumbUpIcon />}
                      {item.failed && <ThumbDownIcon />}

                      {/* <CircularProgress /> */}
                    </ListItem>
                  </>
                )
              })}


            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false);
            props.setView("")
            window.location.assign("/vault")

          }} disabled={!proceedToVault}>Back to Vault</Button>
          {/* <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>



      {fields &&
        fields.map((item, key) => {
          return (
            <CustomTextField
              custom
              form="issue-form"
              key={key}
              label={item.name}
              placeholder={item.name}
              id={item.name}
              required
              name={item.name.replace(/ /g, "")}
              pattern={item.select.value === "number" && "[0-9]{0,5}"}
              type={item.select.value}
            />
          );
        })}
      <CustomTextFieldForm />
      <ButtonContainer>
        <Button
          disabled={!recipientsSelect || !approversSelect === ""}
          form="issue-form"
          className={styles.primaryButton}
          endIcon={<KeyboardArrowRightIcon />}
          type="submit"
        >
          Issue Token
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default TransferCertificate;

const Container = styled.form`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

