import React, { useState } from "react";
import styled from "styled-components";
import ViewHeader from "../ViewHeader";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
// collectibles logo
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
// services logo
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
// real estate logo
import ApartmentIcon from "@mui/icons-material/Apartment";
import InnerCard from "./InnerCard";
import { CustomCreateForm } from "./CustomCreateForm";
import {
  Container,
  GridContainer,
  HeaderContainer,
  LogoWrapper,
} from "./Digital";

// type
var collectibles = "Collectibles";
var services = "Services";
var realEstate = "Real Estate";
// logo
var collectiblesLogo = (
  <CollectionsBookmarkIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
var servicesLogo = (
  <SupervisorAccountIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
var realEstateLogo = (
  <ApartmentIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
// desc
var collectiblesDesc =
  "Tickets: Basic info, like your name and photo that you use on Niftron services. Basic info, like your name and photo that you use on Niftron services.";
var servicesDesc =
  "Services: Basic info, like your name and photo that you use on Niftron services. Basic info, like your name and photo that you use on Niftron services.";
var realEstateDesc =
  "Real Estate: Basic info, like your name and photo that you use on Niftron services. Basic info, like your name and photo that you use on Niftron services.";

const useStyles = makeStyles({
  primaryButton: {
    border: "2px solid #80B7FF",
    height: 60,
    padding: "0 30px",
    textTransform: "inherit",
    fontSize: "18px",
    "&:disabled": {
      color: "#686868b3",
    },
    "&:hover": {
      backgroundColor: "#80B7FF",
    },
  },
});

export default function Physical() {
  const [projectStatus, setProjectStatus] = useState(null);
  const styles = useStyles();
  const [showResults, setShowResults] = useState(false);
  const [realmDetails, setRealmDetails] = useState({
    type: "",
    logo: null,
    desc: "",
  });
  const handleClick = () => {
    setShowResults(true);
  };
  return (() => {
    switch (projectStatus) {
      case "create":
        return <CustomCreateForm projectStatus={setProjectStatus} />;
      default:
        return (
          <Container>
            <HeaderContainer>
              <ViewHeader
                heading="Physical"
                description="Basic info, like your name and photo that you use on Niftron services."
              />
              <LogoWrapper>
                <HomeWorkIcon
                  style={{
                    backgroundColor: "#4a4b96",
                    borderRadius: "50%",
                    padding: "5px",
                    fontSize: "48px",
                  }}
                  fontSize="inherit"
                  color="primary"
                />
              </LogoWrapper>
            </HeaderContainer>
            {showResults && (
              <InnerCard
                type={realmDetails.type}
                logo={realmDetails.logo}
                description={realmDetails.desc}
                projectStatus={setProjectStatus}
              />
            )}
            <GridContainer>
              <Button
                className={styles.primaryButton}
                endIcon={<CollectionsBookmarkIcon />}
                disabled={true}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: collectibles,
                    logo: collectiblesLogo,
                    desc: collectiblesDesc,
                  });
                }}
              >
                {collectibles}
              </Button>
              <Button
                className={styles.primaryButton}
                endIcon={<SupervisorAccountIcon />}
                disabled={true}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: services,
                    logo: servicesLogo,
                    desc: servicesDesc,
                  });
                }}
              >
                {services}
              </Button>
              <Button
                className={styles.primaryButton}
                endIcon={<ApartmentIcon />}
                disabled={true}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: realEstate,
                    logo: realEstateLogo,
                    desc: realEstateDesc,
                  });
                }}
              >
                {realEstate}
              </Button>
            </GridContainer>
          </Container>
        );
    }
  })();
}
