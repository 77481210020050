import React from "react";
import styled from "styled-components";
import { Heading, SmallText } from "../../../../Typography/Typography";
import { Link } from "react-router-dom";

export default function CreateCard(props) {
  return (
    <Container to={props.pathname}>
      <Content>
        <Heading>{props.title}</Heading>
        <LogoWrapper>{props.icon}</LogoWrapper>
      </Content>
      <SmallText>{props.description}</SmallText>
    </Container>
  );
}

const Container = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.creatorMainBg};
  padding: 2rem;
  cursor: pointer;
  transition: all ease-in-out 300ms;
  &:hover {
    background-color: ${(props) => props.theme.colors.createCardHover};
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
