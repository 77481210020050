import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../../Typography/Typography";
import BackToCreate from "../tabViews/Create/BackToCreate";

export default function Tab(props) {
  return (
    <Container>
      <TabButton className="activeTab">
        <Paragraph>{props.tabOne}</Paragraph>
        <Line />
      </TabButton>
      <div></div>
      <div></div>
      <BackButtonWrapper>
        <BackToCreate />
      </BackButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  align-items: center;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-radius: 5px;
  height: 3rem;
  background-color: ${(props) => props.theme.colors.AccountTabsBg};
  @media (max-width: 768px) {
    height: 4rem;
  }
`;
const TabButton = styled.div`
  background-color: transparent;
  position: relative;
  border-radius: 50%;
  outline: none;
  font-family: "Poppins";
  padding: 0.7rem 0rem;
  font-size: 16px;
  font-weight: 300;
  transition: all ease-in 100ms;
  border: none;
  color: ${(props) => props.theme.colors.tabFontColor};
  /* &:hover {
    background-color: ${(props) => props.theme.colors.tabHoverColor};
  } */
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 1.4rem 0rem;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 0.2rem;
  outline: none;
  border: none;
  background-color: ${(props) => props.theme.colors.activeTabColor};
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
`;
