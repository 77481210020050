import React from "react";
import styled from "styled-components";
import CreditBox from "./CreditBox";

export default function Dashboard() {
  return (
    <Container>
      <GridContainer>
        <CreditBox
          title="Remaining Credit"
          tokenAmount="48.57104"
          tokenName="Niftrons"
        />
        <CreditBox title="Projects" tokenAmount="1" />
        <CreditBox title="Tokens Created" tokenAmount="5" />
      </GridContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: 1rem;
  @media (max-width: 1168px) {
    grid-template-columns: 1fr;
  }
`;
