import React from "react";
import styled from "styled-components";
import { Description, NavHeading } from "../../../Typography/Typography";

export default function ViewHeader(props) {
  return (
    <Container>
      <NavHeading>{props.heading}</NavHeading>
      <Description>{props.description}</Description>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 4rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`;
