import styled from "styled-components";

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.paragraphColor};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const Text = styled.p`
  font-size: ${(props) => (props.small ? "0.875rem" : "1.125rem")};
  color: ${(props) => props.theme.colors.textDropColor};
  font-weight: ${(props) => (props.lighter ? "400" : "bold")};
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const Description = styled.h4`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.descriptionColor};
`;

export const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.smallTextColor};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const PrimarySubText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primarySubTextColor};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Heading = styled.h1`
  font-size: 28px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.headingColor};
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const PrimaryHeading = styled.h1`
  font-size: 22px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primaryHeadingColor};
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const TinyText = styled.h1`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.tinyTextColor};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const PrimaryGrayText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primaryGrayTextColor};
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const NavHeading = styled.h3`
  font-size: 22px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.tabFontColor};
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Amount = styled.h1`
  font-size: 30px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.headingColor};
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ButtonSmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.creationCardButton};
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const HeadingCustom = styled.h1`
  font-size: 2rem;
  font-weight: ${(props) => (props.lighter ? "500" : "bold")};
  color: ${(props) => (props.primary ? "#2C2858" : "#fff")};
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

export const SubHeading = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => (props.primary ? "#2C2858" : "#7D7979")};
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
