import React from "react";
import styled from "styled-components";

export default function Token() {
  return (
    <Container>
      <ImageContainer></ImageContainer>
      <DetailContainer>
        <InnerContainer></InnerContainer>
      </DetailContainer>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ImageContainer = styled.div`
  display: flex;
`;

const DetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
