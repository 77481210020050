import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import CreationCard from "./CreationCard";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import VerifiedIcon from "@mui/icons-material/Verified";
import PublicIcon from "@mui/icons-material/Public";
import { useStateValue } from "../../../../context/StateProvider";
import {
  ButtonContainer,
  Container as PreviewContainer,
  DisabledView,
  GridContainer as PreviewGridContainer,
  img,
  thumb,
  thumbInner,
  thumbsContainer,
} from "../Create/CustomCreateForm";
import ViewHeader from "../ViewHeader";
import { ColumnContainer } from "../Create/CustomTextFieldForm";
import { Label } from "../../../CustomTextField";
import { Paragraph } from "../../../../Typography/Typography";

import { getUserSession } from "../../../../services/UserManagement";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getTokenByIdList,
  getTokenBalanceFromStellar,
  getTokenById,
} from "../../../../services/TokenManagement";
import { format } from "timeago.js";
import { useHistory } from "react-router";
import { Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { makeStyles } from "@mui/styles";
import { SwitchTab } from "./components/SwitchTab";
import TransferCertificate from "./components/TransferCertificate";
import Web3 from "web3";
import {
  creatorContracts,
  web3Providers,
} from "../../../../variables/constants";
import { SingleCreationView } from "./components/SingleCreationView.js";

var bscWeb3 = new Web3(web3Providers.providerBscTestNet);
var ethWeb3 = new Web3(web3Providers.providerEthereum);
var maticWeb3 = new Web3(web3Providers.providerMatic);
var bscTestnetWeb3 = new Web3(web3Providers.providerBscTestNet);
var rinkebyWeb3 = new Web3(web3Providers.providerRinkeby);
var mumbaiWeb3 = new Web3(web3Providers.providerMumbai);

var deleteObjects = ["previewUrl", "xdr", "_id"];

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    background: theme.palette.text.button,
    borderRadius: "5px",
    color: theme.palette.text.text,
    padding: "0.6rem 1rem",
    textTransform: "inherit",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#afcef7",
    },
  },
}));

export default function MyCreations() {
  const history = useHistory();
  const styles = useStyles();
  const [view, setView] = useState("");
  const [user, setUser] = useState(getUserSession());
  const [tokens, setTokens] = useState([]);
  const [loading, setloading] = useState(false);
  const [id, setId] = useState();

  var removeObjectProperties = function (obj, props) {
    var dataObject = JSON.parse(JSON.stringify(obj));
    for (var i = 0; i < props.length; i++) {
      if (dataObject.hasOwnProperty(props[i])) {
        delete dataObject[props[i]];
      }
    }
    return dataObject;
  };

  const tokenNames = (names) => {
    return Object.keys(removeObjectProperties(names, deleteObjects));
  };



  const fetchData = async () => {
    try {
      setloading(true);
      const key = user.accounts[0].publicKey;
      let IdList = [];

      if (user.accounts && user.accounts.length >= 3) {
        const ethereumKey = user.accounts[2].publicKey;
        console.log(ethereumKey);

        const ERC721ABI = require("../Create/ERC721.json");


        // //ethereum
        // const contractEthereum = new ethWeb3.eth.Contract(
        //   ERC721ABI,
        //   creatorContracts.ETHEREUM.CREATOR
        // );
        // const tokenCountEthereum = await contractEthereum.methods
        //   .balanceOf(ethereumKey)
        //   .call({ from: ethereumKey });
        // console.log(tokenCountEthereum);

        // for (let j = 0; j < tokenCountEthereum; j++) {
        //   const tokenId = await contractEthereum.methods
        //     .tokenOfOwnerByIndex(ethereumKey, j)
        //     .call({ from: ethereumKey });
        //   IdList.push({
        //     issuer: creatorContracts.ETHEREUM.CREATOR,
        //     id: tokenId,
        //     blockchain: "ETHEREUM",
        //   });
        // }


        //rinkeby
        const contractRinkeby = new rinkebyWeb3.eth.Contract(
          ERC721ABI,
          creatorContracts.RINKEBY.CREATOR
        );
        const tokenCountRinkeby = await contractRinkeby.methods
          .balanceOf(ethereumKey)
          .call({ from: ethereumKey });
        console.log(tokenCountRinkeby);

        for (let j = 0; j < tokenCountRinkeby; j++) {
          const tokenId = await contractRinkeby.methods
            .tokenOfOwnerByIndex(ethereumKey, j)
            .call({ from: ethereumKey });
          IdList.push({
            issuer: creatorContracts.RINKEBY.CREATOR,
            id: tokenId,
            blockchain: "RINKEBY",
          });
        }

        // //bsc
        // const contractBsc = new bscWeb3.eth.Contract(
        //   ERC721ABI,
        //   creatorContracts.BSC.CREATOR
        // );
        // const tokenCountBsc = await contractBsc.methods
        //   .balanceOf(ethereumKey)
        //   .call({ from: ethereumKey });
        // console.log(tokenCountBsc);

        // for (let j = 0; j < tokenCountBsc; j++) {
        //   const tokenId = await contractBsc.methods
        //     .tokenOfOwnerByIndex(ethereumKey, j)
        //     .call({ from: ethereumKey });
        //   IdList.push({
        //     issuer: creatorContracts.BSC.CREATOR,
        //     id: tokenId,
        //     blockchain: "BSC",
        //   });
        // }

        //bsctestnet
        const contractBscTestnet = new bscTestnetWeb3.eth.Contract(
          ERC721ABI,
          creatorContracts.BSCTESTNET.CREATOR
        );
        const tokenCountBscTestnet = await contractBscTestnet.methods
          .balanceOf(ethereumKey)
          .call({ from: ethereumKey });
        console.log(tokenCountBscTestnet);

        for (let j = 0; j < tokenCountBscTestnet; j++) {
          const tokenId = await contractBscTestnet.methods
            .tokenOfOwnerByIndex(ethereumKey, j)
            .call({ from: ethereumKey });
          IdList.push({
            issuer: creatorContracts.BSCTESTNET.CREATOR,
            id: tokenId,
            blockchain: "BSCTESTNET",
          });
        }

        //matic
        const contractMatic = new maticWeb3.eth.Contract(
          ERC721ABI,
          creatorContracts.MATIC.CREATOR
        );
        const tokenCountMatic = await contractMatic.methods
          .balanceOf(ethereumKey)
          .call({ from: ethereumKey });
        console.log(tokenCountMatic);

        for (let j = 0; j < tokenCountMatic; j++) {
          const tokenId = await contractMatic.methods
            .tokenOfOwnerByIndex(ethereumKey, j)
            .call({ from: ethereumKey });
          IdList.push({
            issuer: creatorContracts.MATIC.CREATOR,
            id: tokenId,
            blockchain: "MATIC",
          });
        }


        //mumbai
        const contractMumbai = new mumbaiWeb3.eth.Contract(
          ERC721ABI,
          creatorContracts.MUMBAI.CREATOR
        );
        const tokenCountMumbai = await contractMumbai.methods
          .balanceOf(ethereumKey)
          .call({ from: ethereumKey });
        console.log(tokenCountMumbai);

        for (let j = 0; j < tokenCountMumbai; j++) {
          const tokenId = await contractMumbai.methods
            .tokenOfOwnerByIndex(ethereumKey, j)
            .call({ from: ethereumKey });
          IdList.push({
            issuer: creatorContracts.MUMBAI.CREATOR,
            id: tokenId,
            blockchain: "MUMBAI",
          });
        }
      }


      const balances = await getTokenBalanceFromStellar(key);
      if (balances != null) {
        //console.log(balances)
        balances.forEach((token) => {
          IdList.push({
            id: token.assetCode,
            issuer: token.issuer,
            blockchain: "STELLAR",
          });
        });
      }

      console.log(IdList);
      let result = await getTokenByIdList(IdList);
      if (result != null) {
        if (result.data && result.data.length > 0) {
          setTokens(result.data);
        }
        setloading(false);
      }
      setloading(false);

    } catch (e) {
      console.log(e);
      setloading(false);

    }
  };



  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      {(() => {
        switch (view) {
          case "preview":
            return (<SingleCreationView id={id} setView={setView}
              loading={loading} styles={styles}
              setloading={setloading} />)
              ; 
          default:
            return (
              <Container>
                <GridContainer>
                  {tokens.length === 0 && loading && (
                    <>
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>{" "}
                      <SpinnerBox>
                        <CircularProgress />
                      </SpinnerBox>
                    </>
                  )}
                  {tokens &&
                    tokens.map((token) => {
                      return (
                        <CreationCard
                          token={token}
                          IDkey={token.data._id}
                          primaryTitle={token.data.tokenName.toLowerCase()}
                          secondaryTitle={token.data.category.toLowerCase()}
                          description={token.data.ipfsHash}
                          icon={
                            <ConfirmationNumberIcon
                              fontSize="large"
                              color="primary"
                            />
                          }
                          setView={setView}
                          setId={setId}
                          date={format(token.data.createdAt)}
                        />
                      );
                    })}
                </GridContainer>
              </Container>
            );
        }
      })()}
    </Fragment>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: 1rem;
  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
`;

const SpinnerBox = styled.div`
  width: 100%;
  background-color: #f0f8ff18;
  height: 210px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
