import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NIFTRON } from "niftron-client-sdk";
import Header from "./Header";
import BG from "../images/bg.svg";
import { Paragraph, SmallText } from "../Typography/Typography";
import { LoginIcon } from "./TabComponents/tabViews/MyCreations/components/LoginIcon";

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    background: theme.palette.text.button,
    borderRadius: "5px",
    color: theme.palette.text.text,
    padding: "0.6rem 1rem",
    textTransform: "inherit",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#afcef7",
    },
  },
}));

const Login = () => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <Container>
      <Header loginHeader />
      <FlexContainer>
        {" "}
        <BorderContainer>
          <Button
            startIcon={
              <NiftronLogoWrapper>
                {" "}
                <NiftronLogo src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+SINGLE+LIGHT" />
              </NiftronLogoWrapper>
            }
            variant="contained"
            size="large"
            color="primary"
            className={styles.primaryButton}
            onClick={async () => {
              const token = await NIFTRON.user.popUpAuth();
              if (token) {
                window.location.assign("/");
              }
            }}
          >
            Sign in with
            <span style={{ fontWeight: "bold", marginLeft: "0.3rem" }}>
              {" "}
              Niftron
            </span>
          </Button>
        </BorderContainer>
      </FlexContainer>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  align-items: center;
  position: relative;
  background: url(${BG});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1368px) {
    flex-direction: column;
  }
`;

const BorderContainer = styled.div`
  position: absolute;
  background-color: white;
  top: 50%;
  padding: 2rem 1rem;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  min-width: 15rem;
  width: 25rem;
  max-width: 25rem;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 0.5px solid ${(props) => props.theme.colors.authBorder};
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  flex-direction: column;
`;

const NiftronLogo = styled.img`
  width: 2rem;
  height: auto;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const NiftronLogoWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
  background-color: white;
  border-radius: 50%;
`;
