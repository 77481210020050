import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./components/Header";
import PopUp from "./components/AccountPopUp/PopUp";

//Themes
// import { ThemeProvider as StyledThemeProvider } from "styled-components";
// import { dark } from "./theme/MUI/Dark.js";
// import { light } from "./theme/MUI/Light.js";
// import darkTheme from "./theme/Styled/Dark";
// import lightTheme from "./theme/Styled/Light";

//Redux
import { useMediaQuery } from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import SideNav from "./components/SideNav";
import Dashboard from "./components/TabComponents/CreatorDashboard";
import Creations from "./components/TabComponents/CreatorCreations";
import Create from "./components/TabComponents/CreatorCreate";

// function Root() {
export default function Root() {
  let { tab } = useParams();

  // const isDarkMode = useSelector((state) => state.isDarkMode);
  // // const appliedTheme = createTheme(isDarkMode ? dark : light);
  // const appliedTheme = createTheme(dark);

  const [showNav, setShowNav] = useState(true);
  const [showPopUp, setShowPopUp] = useState(true);
  const matchesTab = useMediaQuery("(min-width:1480px)");

  useEffect(() => {
    const checkDeskTop = () => {
      if (matchesTab) {
        setShowNav(true);
      }
    };
    checkDeskTop();
  }, [matchesTab]);

  // useEffect(() => {
  //   const setThumbScroll = () => {
  //     if (isDarkMode)
  //       document.documentElement.style.setProperty("--thumbBG", "#f8f8f850");
  //     else {
  //       document.documentElement.style.setProperty("--thumbBG", "#202020ae");
  //     }
  //   };
  //   setThumbScroll();
  // }, [isDarkMode]);

  return (
    // <ThemeProvider theme={appliedTheme}>
    //   <CssBaseline />
    //   {/* <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}> */}
    //   <StyledThemeProvider theme={darkTheme}>
    <Container>
      <Header setNav={setShowNav} setShowPopUp={setShowPopUp} />
      <InnerContainer>
        <SideNav showNav={showNav} />
        <SwitchContainer>
          {(() => {
            switch (tab) {
              case "create":
                return <Create />;

              case "vault":
                return <Creations />;

              case "dashboard":
                return <Dashboard />;

              default:
                return <Create />;
            }
          })()}
        </SwitchContainer>
        <PopUp show={showPopUp} setShowPopUp={setShowPopUp}/>
      </InnerContainer>
    </Container>
    //   </StyledThemeProvider>
    // </ThemeProvider>
  );
}

// const authCondition = (authUser) =>
//   (!!authUser && authUser.type === "0") || authUser.type === "2";
// export default withAuthorization(authCondition)(Root);

const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  transition: background 0.5s linear;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  position: relative;
`;

const SwitchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
