import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { Text } from "../../../../Typography/Typography";

export default function DropZoneComponent(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.type,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img alt="preview" src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      props.setFiles(files);
    },
    [files, props]
  );

  return (
    <DroppableContainer>
      <InnerContainer {...getRootProps()}>
        <input id={props.id} {...getInputProps()} />
        {files.length === 0 && (
          <Text primary lighter small>
            {props.description}
          </Text>
        )}
        <aside style={thumbsContainer}>{thumbs}</aside>
      </InnerContainer>
    </DroppableContainer>
  );
}

const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
`;
const InnerContainer = styled.div`
  height: 8rem;
  text-align: center;
  word-wrap: break-word;
  border: dashed 0.75px #ae9bf0a3;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #c3d0e147;
`;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #EAEAEA",
  marginBottom: 8,
  marginRight: 8,
  width: "auto",
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
