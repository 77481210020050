import React, { useEffect, useState } from "react";
import {
    ButtonContainer,
    Container as PreviewContainer,
    DisabledView,
    GridContainer as PreviewGridContainer,
    img,
    thumb,
    thumbInner,
    thumbsContainer,
} from "../../Create/CustomCreateForm";
import { SwitchTab } from "../components/SwitchTab";
import CircularProgress from "@mui/material/CircularProgress";
import TransferCertificate from "../components/TransferCertificate";
import { ColumnContainer } from "../../Create/CustomTextFieldForm";
import { Label } from "../../../../CustomTextField";
import { format } from "timeago.js";
import { Button } from "@mui/material";
import styled from "styled-components";
import {
    getTokenByIdList,
    getTokenBalanceFromStellar,
    getTokenById,
} from "../../../../../services/TokenManagement";
import { getUserSession } from "../../../../../services/UserManagement";
import {
    getProviderByNetwork, getExplorerByNetwork
} from "../../../../../variables/constants";

export const SingleCreationView = ({ id, setView, loading, styles, setloading }) => {
    const [user, setUser] = useState(getUserSession());
    const [switchTab, setSwitchTab] = useState(false);
    const [token, setToken] = useState([]);
    const [tokenCount, setTokenCount] = useState(0);
    const [availableTokenIds, setAvailableTokenIds] = useState([]);

    const [explorerUrl, setExplorerUrl] = useState("")
    const fetchSingleTokenData = async () => {
        setloading(true);
        const balances = await getTokenBalanceFromStellar(user.publicKey);
        let result = await getTokenById(id);
        if (!!id && result != null) {
            if (result.data) {
                if (balances != null) {
                    balances.forEach((sToken) => {
                        if (sToken.assetCode === result.data.assetCode) {
                            result.data.assetCount = sToken.balance * 10000000;
                        }
                    });
                }

                setToken(result.data);
                setTokenCount(result.data.assetCount)
            }

            if (result.data.blockchain !== "STELLAR") {
                setExplorerUrl(getExplorerByNetwork(result.data.blockchain))
                const web3 = getProviderByNetwork(result.data.blockchain);
                const contract = new web3.eth.Contract(
                    result.data.contract,
                    result.data.contractId
                );
                const tokenCount = await contract.methods
                    .balanceOf(user.accounts[2].publicKey)
                    .call({ from: user.accounts[2].publicKey });

                let tokenIdList = []
                for (let j = 0; j < tokenCount; j++) {
                    const tokenId = await contract.methods
                        .tokenOfOwnerByIndex(user.accounts[2].publicKey, j)
                        .call({ from: user.accounts[2].publicKey });
                    if (result.data.tokenIds.indexOf(parseInt(tokenId)) !== -1) {
                        tokenIdList.push(tokenId)
                    }
                }
                setAvailableTokenIds(tokenIdList)
                setTokenCount(tokenIdList.length);
            }


            setloading(false);
        }


        setloading(false);

        // setToken({ "_id": "NN013DEE8486", "tokenName": "The High Queen", "tokenType": "NFT", "assetRealm": "Digital", "tradable": false, "category": "DigitalArt", "assetCode": "NN013DEE8486", "assetIssuer": "GA6T6MHVZBAS6DNFADWJ62RBREUQJQWUVXQMNCYKGAUNYWFIHK7ZUGUV", "assetCount": 1, "previewUrl": "https://niftron-media.s3.us-east-1.amazonaws.com/8aa0a9a6-b9da-4995-95b7-888ce4da4303", "ipfsHash": "QmYvehfWkNuiWJ1rTRnijpJ5S2eNzPHgaFqsWyCFuGLZf9", "price": 0, "xdr": "AAAAAD0/MPXIQS8NpQDsn2ohiSkEwtSt4MaLCjAo3FioOr+aAAAAyAAGkJYAAAADAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgAAAAEAAAAAV5XPUr5nULgs5wUiDAVL+4lg2WMwbthfuDJtSg5IZQ0AAAAGAAAAAk5OMDEzREVFODQ4NgAAAAA9PzD1yEEvDaUA7J9qIYkpBMLUreDGiwowKNxYqDq/mgAAAAAAmJaAAAAAAQAAAAA9PzD1yEEvDaUA7J9qIYkpBMLUreDGiwowKNxYqDq/mgAAAAEAAAAAV5XPUr5nULgs5wUiDAVL+4lg2WMwbthfuDJtSg5IZQ0AAAACTk4wMTNERUU4NDg2AAAAAD0/MPXIQS8NpQDsn2ohiSkEwtSt4MaLCjAo3FioOr+aAAAAAAAAAAEAAAAAAAAAAQ5IZQ0AAABAW2qY3OUmiNFkhsmAgyY/gbqGLpI3O65n/J26qLYwHtH0o6GgbqDY8ELbDrxYZY4lw+AIKabBB3EotjrQtaJ3Cw==", "createdAt": "2020-05-26T13:25:38.174Z" })
        // setloading(false)
    };

    useEffect(() => {
        fetchSingleTokenData();
        setSwitchTab("");

    }, []);
    return (
        <PreviewContainer>
            <PreviewGridContainer>
                <SwitchTab
                    heading="Token Preview"
                    description="View your token details below."
                    active={!switchTab}
                    onClick={() => {
                        setSwitchTab(0);
                        setView("preview");
                    }}
                />
                <SwitchTab
                    heading="Issue Token"
                    description="Transfer your token"
                    active={switchTab}
                    onClick={() => {
                        setSwitchTab(true);
                    }}
                />
            </PreviewGridContainer>
            {loading && (
                <SpinnerBox style={{ width: "100%", height: "30rem" }}>
                    <CircularProgress />
                </SpinnerBox>
            )}
            {!loading && (
                <PreviewGridContainer style={{ marginTop: "2rem" }}>
                    {switchTab ? (
                        <TransferCertificate token={token} availableTokenIds={availableTokenIds} setView={setView}/>
                    ) : (
                        <ColumnContainer style={{ gap: "1rem" }}>
                            {token && (
                                <>
                                    <ColumnContainer>
                                        <Label
                                            style={{
                                                marginBottom: "0.3rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            Token Name
                                        </Label>
                                        <DisabledView>{token.tokenName}</DisabledView>
                                    </ColumnContainer>
                                    <ColumnContainer>
                                        <Label
                                            style={{
                                                marginBottom: "0.3rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            Token Type
                                        </Label>
                                        <DisabledView>{token.tokenType}</DisabledView>
                                    </ColumnContainer>
                                    <ColumnContainer>
                                        <Label
                                            style={{
                                                marginBottom: "0.3rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            Blockchain
                                        </Label>
                                        <DisabledView>{token.blockchain}</DisabledView>
                                    </ColumnContainer>
                                    <ColumnContainer>
                                        <Label
                                            style={{
                                                marginBottom: "0.3rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            Niftron ID
                                        </Label>
                                        <DisabledView>{token.assetCode}</DisabledView>
                                    </ColumnContainer>
                                    <ColumnContainer>
                                        <Label
                                            style={{
                                                marginBottom: "0.3rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            IPFS URL
                                        </Label>
                                        <DisabledView>
                                            <a
                                                target="_blank"
                                                href={`https://cloudflare-ipfs.com/ipfs/${token.ipfsHash}`} rel="noreferrer"
                                            >
                                                {token.ipfsHash}
                                            </a>
                                        </DisabledView>
                                    </ColumnContainer>
                                    <ColumnContainer>
                                        <Label
                                            style={{
                                                marginBottom: "0.3rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            Txn Hash
                                        </Label>
                                        <DisabledView>
                                            {token.blockchain === "STELLAR" ? (
                                                <a
                                                    target="_blank"
                                                    href={`https://laboratory.stellar.org/#explorer?resource=operations&endpoint=for_transaction&values=${encodeURIComponent(
                                                        btoa(
                                                            JSON.stringify({
                                                                transaction: token.txnHash,
                                                            })
                                                        )
                                                    )}&network=test`} rel="noreferrer"
                                                >
                                                    {token.txnHash}
                                                </a>
                                            ) :

                                                (
                                                    <a
                                                        target="_blank"
                                                        href={`${explorerUrl}/tx/${token.txnHash}`} rel="noreferrer"
                                                    >
                                                        {token.txnHash}
                                                    </a>
                                                )
                                            }

                                        </DisabledView>
                                    </ColumnContainer>
                                    <ColumnContainer>
                                        <Label
                                            style={{
                                                marginBottom: "0.3rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            Quantity
                                        </Label>
                                        <DisabledView>{availableTokenIds.length}</DisabledView>
                                    </ColumnContainer>
                                    <ColumnContainer>
                                        <Label
                                            style={{
                                                marginBottom: "0.3rem",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            Created
                                        </Label>
                                        <DisabledView>
                                            {format(token.createdAt)}
                                        </DisabledView>
                                    </ColumnContainer>
                                </>
                            )}
                        </ColumnContainer>
                    )}
                    {token.previewUrl && (
                        <div style={thumbInner}>
                            <img
                                alt="thumbnail"
                                src={token.previewUrl}
                                style={img}
                            />
                        </div>
                    )}
                </PreviewGridContainer>
            )}
            <ButtonContainer>
                <Button
                    form="main-form"
                    className={styles.primaryButton}
                    onClick={() => {
                        setView("");
                    }}
                >
                    Go Back
                </Button>
            </ButtonContainer>
        </PreviewContainer>
    );
};
const SpinnerBox = styled.div`
  width: 100%;
  background-color: #f0f8ff18;
  height: 210px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
