import React from "react";
import { useParams } from "react-router";
import Create from "./Create";
import Digital from "./Digital";
import Physical from "./Physical";

export default function CreateSwitch() {
  const { view } = useParams();
  return (
    <>
      {(() => {
        switch (view) {
          case "digital":
            return <Digital />;
          case "physical":
            return <Physical />;
          default:
            return <Create />;
        }
      })()}
    </>
  );
}
