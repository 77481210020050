import { Blockchain } from "niftron-client-sdk";
import Web3 from "web3";

// dev
// // export var niftronUserLambda = "http://localhost:7000/api/v1";
// export var niftronUserLambda =
//   "https://8gq4vhf2j3.execute-api.us-east-1.amazonaws.com/dev";
// // export var niftronTokenLambda = "http://localhost:7001/api/v1";
// export var niftronTokenLambda =
//   "https://rdty586fta.execute-api.us-east-1.amazonaws.com/dev";
// export var NiftronApi = "https://dev-apiv2.niftron.com";
// export var StellarUrlTest = "https://horizon-testnet.stellar.org";
// export var StellarUrl = "https://horizon.stellar.org";
// export var StellarNetwork = "TestNet";
// export var Version = "0.01";
// export var NiftronIssuerPublicKey =
//   "GCN4TGLXKUDTM3PG7M4K7IJCPRC4NAFF3S6CDPWO5FDPAL6QURWRGIEM";
// export var NiftronDistributorPublicKey =
//   "GCC4PL7FJJ74DGVAU3ODHU4HPU7E6CNQ6XMMV3CKEC4GFB4QG3W4L7ZF";
// export var niftronProfileURL = "https://dev.profile.niftron.com";
// export var niftronConsoleURL = "https://dev.console.niftron.com";

//prod
// export var niftronUserLambda = "http://localhost:7000/api/v1";
export var niftronUserLambda =
  "https://32o47h3pc2.execute-api.us-east-1.amazonaws.com/prod";
// export var niftronTokenLambda = "http://localhost:7001/api/v1";
export var niftronTokenLambda =
  "https://y548z5pqmi.execute-api.us-east-1.amazonaws.com/prod";
export var NiftronAPI = "https://api.niftron.com";
export var StellarUrlTest = "https://horizon-testnet.stellar.org";
export var StellarUrl = "https://horizon.stellar.org";
export var StellarNetwork = "TestNet";
export var Version = "0.01";
export var NiftronIssuerPublicKey =
  "GBIV5P2STT7M7633B4IU54MUFBKWELUX5J7ELA5QBXUGRXM4MQYKJHE7";
export var NiftronDistributorPublicKey =
  "GCC4PL7FJJ74DGVAU3ODHU4HPU7E6CNQ6XMMV3CKEC4GFB4QG3W4L7ZF";
export var niftronProfileURL = "https://profile.niftron.com";
export var niftronConsoleURL = "https://console.niftron.com";

export const creatorContracts = {
  ETHEREUM: {
    CREATOR: "",
  },
  RINKEBY: {
    CREATOR: "0x7771Df89a04312494257C36A0859a6Bc210a0916",
  },
  BSC: {
    CREATOR: "",
  },
  BSCTESTNET: {
    CREATOR: "0x2A8032331E2cC688d77B2F77cE773eeA76Fc320F",
  },
  MATIC: {
    CREATOR: "0x55080f6b912B8F0689d298AA759A39705e6f9C0E",
  },
  MUMBAI: {
    CREATOR: "0x78Ce95528DC2Cf8d6658997cC7DA40C1d0e5cDf5",
  },
};

export const creatorIssuer = {
  CREATOR: "GCN4TGLXKUDTM3PG7M4K7IJCPRC4NAFF3S6CDPWO5FDPAL6QURWRGIEM",
};

export const web3Providers = {
  providerBscTestNet: new Web3.providers.HttpProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545/"
  ),
  providerRinkeby: new Web3.providers.HttpProvider(
    "https://rinkeby.infura.io/v3/1ae5799b9f6c4321951ad280f2b82a0f"
  ),
  providerBsc: new Web3.providers.HttpProvider(
    "https://bsc-dataseed1.ninicoin.io"
  ),
  providerEthereum: new Web3.providers.HttpProvider(
    'https://mainnet.infura.io/v3/1ae5799b9f6c4321951ad280f2b82a0f'
  ),
  providerMatic: new Web3.providers.HttpProvider(
    'https://rpc-mainnet.maticvigil.com/v1/0e23e37bcd301b8ff7c20ea76b22bd763f70e3ba'
  ),
  providerMumbai: new Web3.providers.HttpProvider(
    'https://rpc-mumbai.maticvigil.com/v1/0e23e37bcd301b8ff7c20ea76b22bd763f70e3ba'
  )
}

export const getProviderByNetwork = (blockchain) => {
  switch (blockchain) {
    case Blockchain.ETHEREUM: return new Web3(web3Providers.providerEthereum);
    case Blockchain.BSC: return new Web3(web3Providers.providerBsc);
    case Blockchain.MATIC: return new Web3(web3Providers.providerMatic);
    case Blockchain.RINKEBY: return new Web3(web3Providers.providerRinkeby);
    case Blockchain.BSCTESTNET: return new Web3(web3Providers.providerBscTestNet);
    case Blockchain.MUMBAI: return new Web3(web3Providers.providerMumbai);
    default: return new Web3(web3Providers.providerEthereum);
  }
}

export const getExplorerByNetwork = (blockchain) => {
  switch (blockchain) {
    case Blockchain.ETHEREUM: return "https://etherscan.io";
    case Blockchain.BSC: return "https://bscscan.com";
    case Blockchain.MATIC: return"https://polygonscan.com"; 
    case Blockchain.RINKEBY: return "https://rinkeby.etherscan.com";
    case Blockchain.BSCTESTNET: return "https://testnet.bscscan.com";
    case Blockchain.MUMBAI: return "https://mumbai.polygonscan.com";
    default: return "https://etherscan.io";
  }
}