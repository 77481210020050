import { Text } from "../Typography/Typography";
import React, { Fragment } from "react";
import styled from "styled-components";

import CancelIcon from "@mui/icons-material/Cancel";
import { useStateValue } from "../context/StateProvider";

export const CustomTextField = (props) => {
  const [{ fields }, dispatch] = useStateValue();

  const handleRemoveField = (name) => {
    if (
      window.confirm(
        `Are you sure you want to delete the field named ${props.label} ? `
      )
    ) {
      dispatch({
        type: "REMOVE_FIELD",
        id: name,
      });
    }
  };

  return (
    <Container span={props.span}>
      <InputContainer custom={props.custom}>
        <Label id={props.id}>{props.label}</Label>
        {props.custom && (
          <CancelIcon
            onClick={() => {
              handleRemoveField(props.id);
            }}
            size="small"
            style={{ color: "#a8a6a6", cursor: "pointer" }}
          />
        )}
      </InputContainer>
      <TextInputContainer>
        <Input
          form={props.form}
          disabled={props.disabled}
          type={props.type}
          placeholder={props.placeholder}
          required={props.required}
          onChange={
            props.onChange && (({ target: { value } }) => props.onChange(value))
          }
          value={props.value}
          name={props.name}
          id={props.id}
          error={props.error}
          control={props.control}
          onBlur={props.onBlur}
          onKeyPress={props.onKeyPress}
        />
        <FlexContainer>
          <Text lighter small>
            {props.helper}
          </Text>
          <Fragment>{props.icon}</Fragment>
        </FlexContainer>
      </TextInputContainer>
    </Container>
  );
};

const TextInputContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  background-color: ${(props) => props.theme.colors.textFieldColor};
  border-radius: 5px;
  align-items: center;
`;

const Input = styled.input`
  border: none;
  width: 70%;
  padding: 0 0.5rem;
  outline: none;
  color: #2c2858;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  @media (max-width: 1368px) {
    font-size: 14px;
    width: 50%;
  }
`;

export const Label = styled.label`
  color: ${(props) => props.theme.colors.labelColor};
  font-weight: 400;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Container = styled.div`
  gap: 0.3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-column: ${(props) => (props.span ? "1/3" : "unset")};
  @media (max-width: 768px) {
    grid-column: unset;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 30%;
  align-items: center;
  @media (max-width: 1368px) {
    width: 50%;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => (props.custom ? "0.2rem" : "0")}; ;
`;
