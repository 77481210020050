import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Paragraph,
  Description,
  SmallText,
  ButtonSmallText,
} from "../../../../Typography/Typography";
import { Button } from "@mui/material";
import { border } from "@mui/system";
import { getIPFSData } from "../../../../services/TokenManagement";
export default function CreationCard(props) {
  const [description, setDescription] = useState("...");
  const [image, setImage] = useState("...");

  const fetchData = async () => {
    const dataRes = await getIPFSData(props.description);
    if (dataRes != null) {
      console.log(dataRes.description);
      if (typeof dataRes.description == "string") {
        setDescription(dataRes.description);
        setImage(dataRes.image);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = () => {
    props.setId(props.IDkey);
    props.setView("preview");
    console.log("child", props.IDkey);
  };

  return (
    <Container key={props.IDkey}>
      <Content>
        <div>
          <Paragraph style={{ textTransform: "capitalize" }}>
            {props.primaryTitle}
          </Paragraph>
          <Description style={{ textTransform: "capitalize" }}>
            {props.secondaryTitle}
          </Description>
        </div>
        <LogoWrapper>{props.token.data.tokenIds && props.token.data.tokenIds.length}<img src={image} height="30" width="30" alt="img" onerror={() => {
          const splitUrl = image.split("/");
          setImage(`https://cloudflare-ipfs.com/ipfs/${splitUrl[splitUrl.length - 1]}`)
        }} />

          {/* {props.icon} */}
        </LogoWrapper>
      </Content>
      <FlexContainer>
        <SmallText>{description}</SmallText>
      </FlexContainer>
      <DetailWrapper>
        <Button
          style={{ textTransform: "capitalize", border: "1px solid #12183a37" }}
          onClick={handleClick}
        >
          <ButtonSmallText>View More</ButtonSmallText>
        </Button>
        <SmallText>{props.date}</SmallText>
      </DetailWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.creatorMainBg};
  padding: 1rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.div`
  width: 3rem;
  background-color: ${(props) => props.theme.colors.logoCardBg};
  height: 3rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
`;
