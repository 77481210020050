import React from "react";
import styled from "styled-components";
import {
  Description,
  SmallText,
  Amount,
} from "../../../../Typography/Typography";

export default function CreditBox(props) {
  return (
    <Container>
      <Content>
        <SmallText>{props.title}</SmallText>
        <TokenWrapper>
          <Amount>{props.tokenAmount}</Amount>
          <Description>{props.tokenName}</Description>
        </TokenWrapper>
      </Content>
      <NiftronRoundLogo>
        <Logo
          src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+SINGLE+LIGHT"
          alt="Niftron Logo"
        />
      </NiftronRoundLogo>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.creatorMainBg};
  padding: 1rem;
`;

const NiftronRoundLogo = styled.div`
  width: 4rem;
  background-color: #fff;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Logo = styled.img`
  height: auto;
  width: 100%;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr;
`;

const TokenWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: baseline;
`;
