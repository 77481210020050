import React, { useState } from "react";
import styled from "styled-components";
import ViewHeader from "../ViewHeader";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// tickets logo
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
// badges logo
import VerifiedIcon from "@mui/icons-material/Verified";
// certificates logo
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
// domains logo
import PublicIcon from "@mui/icons-material/Public";
// records logo
import DescriptionIcon from "@mui/icons-material/Description";
// art logo
import BrushIcon from "@mui/icons-material/Brush";
// other logo
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import InnerCard from "./InnerCard";
import { CustomCreateForm } from "./CustomCreateForm";

// type
var tickets = "Tickets";
var domains = "Domains";
var certificates = "Certificates";
var badges = "Badges";
var records = "Records";
var art = "Art";
var other = "Other";
// logo
var ticketsLogo = (
  <ConfirmationNumberIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
var domainsLogo = (
  <PublicIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
var certificatesLogo = (
  <VerifiedUserIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
var badgesLogo = (
  <VerifiedIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
var recordsLogo = (
  <DescriptionIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
var artLogo = (
  <BrushIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
var otherLogo = (
  <ControlPointIcon
    style={{
      padding: "5px",
      fontSize: "40px",
    }}
    fontSize="inherit"
    color="primary"
  />
);
// desc
var ticketsDesc =
  "Tickets: Allows the creation of tickets which can grant access to holders.";
var domainsDesc =
  "Domains: Allows the creation of domain names which can grant ownership to holders.";
var certificatesDesc =
  "Certificates: Allows the creation of certificates which can grant ownership to holders.";
var badgesDesc =
  "Badges: Allows the creation of badges which can grant ownership to holders.";
var recordsDesc =
  "Records: Allows the creation of records which can grant ownership to holders.";
var artDesc =
  "Art: Allows the creation of art which can grant ownership to holders.";
var otherDesc =
  "Other: Allows the creation of anything which can grant ownership to holders.";

const useStyles = makeStyles({
  primaryButton: {
    border: "2px solid #80B7FF",
    height: 60,
    padding: "0 30px",
    textTransform: "inherit",
    fontSize: "18px",
    "&:disabled": {
      color: "#686868b3",
    },

    "&:hover": {
      backgroundColor: "#80B7FF",
    },
  },
});

export default function Digital() {
  const [projectStatus, setProjectStatus] = useState(null);
  const styles = useStyles();
  const [showResults, setShowResults] = useState(false);
  const [realmDetails, setRealmDetails] = useState({
    type: "",
    logo: null,
    desc: "",
  });
  const handleClick = () => {
    setShowResults(true);
  };
  return (() => {
    switch (projectStatus) {
      case "create":
        return <CustomCreateForm projectStatus={setProjectStatus} />;
      default:
        return (
          <Container>
            <HeaderContainer>
              <ViewHeader
                heading="Digital"
                description="Basic info, like your name and photo that you use on Niftron services."
              />
              <LogoWrapper>
                <MonetizationOnIcon
                  style={{
                    backgroundColor: "#4a4b96",
                    borderRadius: "50%",
                    padding: "5px",
                    fontSize: "48px",
                  }}
                  fontSize="inherit"
                  color="primary"
                />
              </LogoWrapper>
            </HeaderContainer>
            {showResults && (
              <InnerCard
                type={realmDetails.type}
                logo={realmDetails.logo}
                description={realmDetails.desc}
                projectStatus={setProjectStatus}
              />
            )}
            <GridContainer>
              <Button
                className={styles.primaryButton}
                endIcon={<ConfirmationNumberIcon />}
                disabled={true}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: tickets,
                    logo: ticketsLogo,
                    desc: ticketsDesc,
                  });
                }}
              >
                {tickets}
              </Button>
              <Button
                className={styles.primaryButton}
                endIcon={<PublicIcon />}
                disabled={true}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: domains,
                    logo: domainsLogo,
                    desc: domainsDesc,
                  });
                }}
              >
                {domains}
              </Button>
              <Button
                className={styles.primaryButton}
                endIcon={<VerifiedUserIcon />}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: certificates,
                    logo: certificatesLogo,
                    desc: certificatesDesc,
                  });
                }}
              >
                {certificates}
              </Button>
              <Button
                className={styles.primaryButton}
                endIcon={<VerifiedIcon />}
                disabled={true}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: badges,
                    logo: badgesLogo,
                    desc: badgesDesc,
                  });
                }}
              >
                {badges}
              </Button>
              <Button
                className={styles.primaryButton}
                endIcon={<DescriptionIcon />}
                disabled={true}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: records,
                    logo: recordsLogo,
                    desc: recordsDesc,
                  });
                }}
              >
                {records}
              </Button>
              <Button
                className={styles.primaryButton}
                endIcon={<BrushIcon />}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: art,
                    logo: artLogo,
                    desc: artDesc,
                  });
                }}
              >
                {art}
              </Button>
              <Button
                className={styles.primaryButton}
                endIcon={<ControlPointIcon />}
                onClick={() => {
                  handleClick();
                  setRealmDetails({
                    ...realmDetails,
                    type: other,
                    logo: otherLogo,
                    desc: otherDesc,
                  });
                }}
              >
                {other}
              </Button>
            </GridContainer>
          </Container>
        );
    }
  })();
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  @media (max-width: 968px) {
    margin-bottom: 2rem;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: 2rem;
  align-content: center;
  @media (max-width: 968px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
