import React, { useState } from "react";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

import { IconButton } from "@mui/material";
import { Paragraph } from "../Typography/Typography";

//MUI
import LockIcon from "@mui/icons-material/Lock";

import CreateIcon from "@mui/icons-material/Create";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Fade from "@mui/material/Fade";

export default function SideNav(props) {
  const matchesTab = useMediaQuery("(min-width:1480px)");
  const matchesMobile = useMediaQuery("(min-width:768px)");
  const [project, setProject] = useState("Niftron Projects");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNetSwitch = (type) => {
    setProject(type);
  };

  return (
    <div style={{ position: "relative" }}>
      {matchesMobile ? (
        <>
          <OuterContainer>
            <Container
              className={`${
                props.showNav ? "hideNav-after" : "hideNav-before"
              }`}
            >
              <NavContainer>
                {/* <NavLink to="" activeClassName="">
                  <IconButton>
                    <AccountTreeIcon color="primary" />
                  </IconButton>
                  {!matchesTab ? null : <Paragraph>Select Project</Paragraph>}
                </NavLink> */}
                <NavLink to="/create" activeClassName="active">
                  <IconButton>
                    <CreateIcon color="primary" />
                  </IconButton>
                  {!matchesTab ? null : <Paragraph>Create</Paragraph>}
                </NavLink>
                <NavLink to="/vault" activeClassName="active">
                  <IconButton>
                    <LockIcon color="primary" />
                  </IconButton>
                  {!matchesTab ? null : <Paragraph>My Vault</Paragraph>}
                </NavLink>
                {/* <NavLink to="/dashboard" activeClassName="active">
                  <IconButton>
                    <DashboardIcon color="primary" />
                  </IconButton>
                  {!matchesTab ? null : <Paragraph>Dashboard</Paragraph>}
                </NavLink> */}
              </NavContainer>
            </Container>
            <DocContainer>
              {" "}
              <NavLink to="/documentation">
                <IconButton>
                  <LibraryBooksIcon color="primary" />
                </IconButton>
                {!matchesTab ? null : <Paragraph>Documentation</Paragraph>}
              </NavLink>
            </DocContainer>
          </OuterContainer>
          {!matchesTab && (
            <ContainerAfter
              className={`${
                props.showNav ? "hideNav-before" : "hideNav-after"
              }`}
            >
              <NavContainer>
                {/* <NavLinkAfter to="" activeClassName="">
                  <IconButton>
                    <AccountTreeIcon color="primary" />
                  </IconButton>
                  {!matchesTab ? null : <Paragraph>Select Project</Paragraph>}
                  <Button
                    disableRipple
                    id="fade-button"
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    {project}
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleNetSwitch("Niftron Projects");
                      }}
                    >
                      Niftron Projects
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleNetSwitch("Other Projects");
                      }}
                    >
                      Other Projects
                    </MenuItem>
                  </Menu>
                </NavLinkAfter> */}
                <NavLinkAfter to="/create" activeClassName="active">
                  <IconButton>
                    <CreateIcon color="primary" />
                  </IconButton>
                  {!matchesTab && <Paragraph>Create</Paragraph>}
                </NavLinkAfter>
                <NavLinkAfter to="/vault" activeClassName="active">
                  <IconButton>
                    <CreateNewFolderIcon color="primary" />
                  </IconButton>
                  {!matchesTab && <Paragraph>My Vault</Paragraph>}
                </NavLinkAfter>
                {/* <NavLinkAfter to="/dashboard" activeClassName="active">
                  <IconButton>
                    <DashboardIcon color="primary" />
                  </IconButton>
                  {!matchesTab && <Paragraph>Dashboard</Paragraph>}
                </NavLinkAfter> */}
              </NavContainer>
              <NavLinkAfter to="/documentation">
                <IconButton>
                  <LibraryBooksIcon color="primary" />
                </IconButton>
                {!matchesTab && <Paragraph>Documentation</Paragraph>}
              </NavLinkAfter>
            </ContainerAfter>
          )}
        </>
      ) : (
        <ContainerAfter
          className={`${props.showNav ? "hideNav-before" : "hideNav-after"}`}
        >
          <NavContainer>
            {/* <NavLinkAfter to="" activeClassName="">
              <IconButton>
                <AccountTreeIcon color="primary" />
              </IconButton>
              {!matchesTab ? null : <Paragraph>Select Project</Paragraph>}
              <Button
                disableRipple
                id="fade-button"
                aria-controls="fade-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {project}
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleNetSwitch("Niftron Projects");
                  }}
                >
                  Niftron Projects
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    handleNetSwitch("Other Projects");
                  }}
                >
                  Other Projects
                </MenuItem>
              </Menu>
            </NavLinkAfter> */}
            <NavLinkAfter to="/create" activeClassName="active">
              <IconButton>
                <CreateIcon color="primary" />
              </IconButton>
              {!matchesTab && <Paragraph>Create</Paragraph>}
            </NavLinkAfter>
            <NavLinkAfter to="/vault" activeClassName="active">
              <IconButton>
                <CreateNewFolderIcon color="primary" />
              </IconButton>
              {!matchesTab && <Paragraph>My Vault</Paragraph>}
            </NavLinkAfter>
            {/* <NavLinkAfter to="/dashboard" activeClassName="active">
              <IconButton>
                <DashboardIcon color="primary" />
              </IconButton>
              {!matchesTab && <Paragraph>Dashboard</Paragraph>}
            </NavLinkAfter> */}
          </NavContainer>
          <NavLinkAfter to="/documentation">
            <IconButton>
              <LibraryBooksIcon color="primary" />
            </IconButton>
            {!matchesTab && <Paragraph>Documentation</Paragraph>}
          </NavLinkAfter>
        </ContainerAfter>
      )}
    </div>
  );
}

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-width: 20rem;
  border-right: 0.2px solid ${(props) => props.theme.colors.authBorder};

  transition: all ease-in-out 300ms;
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 1480px) {
    min-width: 10%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 90%;
  width: 100%;
  padding: 2rem 0;
`;

const NavContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const NavLink = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;
  padding: 0.25rem;
  cursor: pointer;
  transition: all ease-in-out 300ms;
  &.active {
    background-color: ${(props) => props.theme.colors.navActive};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.navItem};
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 5fr;
  }
  @media (max-width: 1480px) {
    grid-template-columns: 1fr;
  }
`;

const NavLinkAfter = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  text-decoration: none;
  border-radius: 5px;
  transition: all ease;
  cursor: pointer;
  transition: all ease-in-out 300ms;
  &.active {
    background-color: ${(props) => props.theme.colors.navActive};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.navItem};
  }
`;

const ContainerAfter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: ${(props) => props.theme.colors.authHeaderShadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-width: 20rem;
  padding: 2rem 0;
  border-right: 0.2px solid ${(props) => props.theme.colors.authBorder};
  transition: all ease-in-out 300ms;
`;

const DocContainer = styled.div`
  width: 100%;
  border-top: 0.2px solid ${(props) => props.theme.colors.authBorder};
  padding: 1rem;
`;
