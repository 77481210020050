const lightTheme = {
  colors: {
    background: "#fff",
    paragraphColor: "#12183a",
    descriptionColor: "#3f3f3f",
    smallTextColor: "#12183a",
    primarySubTextColor: "#006eff",
    headingColor: "#12183a",
    primaryHeadingColor: "#006eff",
    tinyTextColor: "#202020a9",
    authBox: "#ffffff",
    authBorder: "#47474740",
    authActiveHeader: "#fff",
    authInActiveHeader: "#fff",
    authHeaderShadow: "0 3px 12px 0 rgb(18 24 58 / 11%)",
    authHeaderColor: "#ffffff",
    privacyCardBg: "#e3ebfc",
    modalBg: "#eaedff",
    copyPasteBackground: "#79797926",
    primaryGrayTextColor: "#7a7a7add",
    navItem: "#11093e16",
    navActive: "#80B7FF",
    AccountTabsBg: "#80B7FF",
    tabFontColor: "#12183a",
    tabHoverColor: "#ffffff26",
    activeTabColor: "#12183a9b",
    typeFlash: "#8280ff34",
    textFieldColor: "#80b7ff88",
    labelColor: "#2b2525",
    textDropColor: "#12183a",

    // creator
    creatorMainBg: "#11093e16",
    logoCardBg: "#fff",
    creationCardButton: "#12183a",
    createCardHover: "#80B7FF",
    switchTab: "#80b7ff63",
    switchTabActive: "#80b7ff",

    //Table
    tableHeadBg: "#80B7FF",
    tableBg: "#80b7ff2f",
    tableRowHover: "#80b7ff68",

    //PopUp
    hoverColor: "#4d4d4d34",

    //CreditBG
    creditBGColor: "#80b7ff2f",
  },
};

export default lightTheme;
