import React, { useState } from "react";
import styled from "styled-components";
import Tab from "./Tab/Tab";
import TabView from "./Tab/TabView";
import Dashboard from "./tabViews/Dashboard/Dashboard";

//Set Tabs
var tabOne = "Dashboard";

export default function CreatorDashboard() {
  const [view, setView] = useState(tabOne);

  return (
    <Container>
      <Tab tabOne={tabOne} setActive={setView} reuse />
      <TabView component={<Dashboard />} />
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  justify-content: flex-start;
  width: 100%;
  min-height: 92vh;
`;
