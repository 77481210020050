import React, { useState, useEffect } from "react";

import styled from "styled-components";

import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PrimaryGrayText } from "../Typography/Typography";

//MUI
import { IconButton, Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Fade from "@mui/material/Fade";
import { NIFTRON } from "niftron-client-sdk";
import jwt from "jsonwebtoken";
export default function Header(props) {
  const matchesTab = useMediaQuery("(min-width:1480px)");
  const [user, setUser] = useState(null);

  //TestNet
  const [net, setNet] = useState("SandBox");

  const [showNav, setShowNav] = useState(false);
  const [showPopUp, setShowPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNetSwitch = (type) => {
    setNet(type);
  };

  useEffect(() => {
    const getUser = () => {
      if (localStorage.getItem("niftoken")) {
        const token = localStorage.getItem("niftoken");
        if (token != null) {
          const decodedToken = jwt.decode(token);
          if (decodedToken != null) {
            setUser(decodedToken);
          }
        }
      }
    };
    getUser();
  }, []);

  if (props.loginHeader) {
    return (
      <Container style={{ width: "100%", background: "#fff" }}>
        <LogoContainer>
          <Logo
            src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+FULL+LIGHT"
            alt="Niftron"
          />
          <PrimaryGrayText>Creator</PrimaryGrayText>
        </LogoContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <LogoContainer>
          {!matchesTab && (
            <Hamburger>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  setShowNav((value) => !value);
                  props.setNav(showNav);
                }}
              >
                <MenuIcon fontSize="small" color="primary" />
              </IconButton>
            </Hamburger>
          )}
          <Logo
            src="https://niftron-logo.s3.amazonaws.com/NIFTRON+LOGO+FULL+LIGHT"
            alt="Niftron"
          />
          <PrimaryGrayText>Creator</PrimaryGrayText>
        </LogoContainer>
        <MenuWrapper>
          {/* <Button
            disableRipple
            id="fade-button"
            aria-controls="fade-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {net}
          </Button> */}
          {/* <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                handleNetSwitch("SandBox");
              }}
            >
              SandBox
            </MenuItem>
            <MenuItem
              disabled
              onClick={() => {
                handleClose();
                handleNetSwitch("LiveNet");
              }}
            >
              LiveNet
            </MenuItem>
          </Menu> */}

          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setShowPopup((value) => !value);
              props.setShowPopUp(showPopUp);
            }}
          >
            {user && (
              <Avatar
                // src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                alt={user.alias}
              />
            )}
          </IconButton>
        </MenuWrapper>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 8vh;
  min-height: 70px;
  padding: 0.5rem 2rem;
  justify-content: space-between;
  border-bottom: 0.1px solid ${(props) => props.theme.colors.authBorder};
  @media (max-width: 768px) {
    height: 9vh;
    padding: 0.5rem 0.5rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const Logo = styled.img`
  width: 8rem;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  height: auto;
`;

const Hamburger = styled.div`
  padding: 0.25rem;
  border-radius: 50%;
  border: 0.5px solid ${(props) => props.theme.colors.authBorder};
`;

const MenuWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
