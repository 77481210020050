import React from "react";
import styled from "styled-components";

export default function TabView(props) {
  return <Container>{props && props.component}</Container>;
}

const Container = styled.div`
  width: 100%;
  padding: 2rem 3rem;
  border-radius: 5px;
  border: 0.2px solid ${(props) => props.theme.colors.authBorder};
`;
