export const dark = {
  palette: {
    text: {
      default: "#f7f7f7",
      button: "#80B7FF",
      text: "#11093E",
      icon: "#6687f18d",
    },
    table: {
      header: "#aab0ff",
    },
    textColor: "#000000",
    overrides: {
      MuiInput: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "none",
        },
      },
    },
    primary: {
      main: "#fcfcfc",
    },
    secondary: {
      main: "#ffffff",
    },
    textField: {
      main: "#dddddd37",
    },
    type: "dark",
  },
  typography: {
    fontFamily: ["Poppins"].join(","),
  },
};
