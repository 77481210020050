import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import jwt from "jsonwebtoken";

import Root from "./Root";
import Auth from "./components/Login";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { Provider } from "react-redux";
import store from "./redux/reducers";
import GuardedRoute from "react-guarded-route";
import { NIFTRON } from "niftron-client-sdk";

//Themes
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { dark } from "./theme/MUI/Dark.js";
import { light } from "./theme/MUI/Light.js";
import darkTheme from "./theme/Styled/Dark";
import lightTheme from "./theme/Styled/Light";

//Redux
import { useSelector } from "react-redux";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Token from "./components/TabComponents/tabViews/MyCreations/Token";

import reducer, { initialstate } from "./context/reducer";
import { StateProvider } from "./context/StateProvider";
import { SnackbarProvider } from "notistack";
import CreationCard from "./components/TabComponents/tabViews/MyCreations/CreationCard";
import MyCreations from "./components/TabComponents/tabViews/MyCreations/MyCreations";

require("dotenv").config();
const niftronConfig = {
  projectKey: process.env.REACT_APP_PROJECT_KEY,
  secretKey: process.env.REACT_APP_DEV_SECRET_KEY,
};

const Niftron = new NIFTRON(niftronConfig);

Niftron.initialize();
const authValidator = () => {
  if (localStorage.getItem("niftoken")) {
    const token = localStorage.getItem("niftoken");
    if (token != null) {
      const decodedToken = jwt.decode(token);
      if (decodedToken == null) {
        localStorage.removeItem("secretKey");
        localStorage.removeItem("niftoken");
        return false;
      } else {
        if (
          new Date(decodedToken.exp).toISOString() > new Date().toISOString()
        ) {
          localStorage.removeItem("secretKey");
          localStorage.removeItem("niftoken");
          return false;
        } else {
          if (decodedToken && decodedToken.type === "0") {
            return true;
          } else {
            localStorage.removeItem("secretKey");
            localStorage.removeItem("niftoken");
            return false;
          }
        }
      }
    }
  }
};

const App = () => {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  // const appliedTheme = createTheme(isDarkMode ? dark : light);
  const appliedTheme = createTheme(light);

  useEffect(() => {
    const setThumbScroll = () => {
      if (isDarkMode)
        document.documentElement.style.setProperty("--thumbBG", "#f8f8f850");
      else {
        document.documentElement.style.setProperty("--thumbBG", "#202020ae");
      }
    };
    setThumbScroll();
  }, [isDarkMode]);

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />
      {/* <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}> */}
      <StyledThemeProvider theme={lightTheme}>
        <Router>
          <Switch>
            {/* <Route path="/" component={Root} exact />
            <Route path="/:tab" component={Root} /> */}
            <Redirect exact from="/" to="/create" />
            <Route path="/auth" component={Auth} exact />
            <Route path="/token" component={Token} exact />

            <GuardedRoute
              path="/:tab"
              component={Root}
              redirectTo="/auth" //(Optional) Redirect to '/login' if validatorFunction returns false. Will redirect to '/' if not provided.
              validatorFunction={authValidator()}
            />
          </Switch>
        </Router>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StateProvider initialstate={initialstate} reducer={reducer}>
        <SnackbarProvider
          maxSnack={2}
          preventDuplicate
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <App />
          {/* <Router>
            <Switch>
              <Route path="/" component={Root} exact />
              <Route path="/:tab" component={Root} />
            </Switch>
          </Router> */}
        </SnackbarProvider>
      </StateProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
