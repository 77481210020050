import { Button, IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { makeStyles } from "@mui/styles";
import { Description, NavHeading } from "../../../../Typography/Typography";

const useStyles = makeStyles({
  primaryButton: {
    background: "#80B7FF",
    borderRadius: "5px",
    color: "#11093E",
    padding: "0.5rem 1rem",
    textTransform: "inherit",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#afcef7",
    },
  },
});

export default function InnerCard(props) {
  const styles = useStyles();
  return (
    <Container>
      <HeaderContainer>
        <NavHeading style={{ fontSize: "20px" }}>{props.type}</NavHeading>
        <Description>{props.description}</Description>
      </HeaderContainer>

      <>
        <ButtonContainer>
          {props.logo}
          <Button
            onClick={() => {
              props.projectStatus("create");
            }}
            className={styles.primaryButton}
            endIcon={<KeyboardArrowRightIcon />}
          >
            Create
          </Button>
        </ButtonContainer>
      </>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.creatorMainBg};
  padding: 1.5rem 2rem;
  gap: 1rem;
  @media (max-width: 968px) {
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  @media (max-width: 968px) {
    align-items: unset;
  }
`;
