/* eslint-disable */
import axios from "axios";
import { niftronTokenLambda } from "../variables/constants";
import { NiftronIssuerPublicKey, NiftronAPI } from "../variables/constants";
import { Server } from "stellar-sdk";
import store from "../redux/reducers";
let StellarUrl = store.getState().StellarUrl;
let StellarUrlTest = store.getState().StellarUrlTest;
store.subscribe(() => {
  StellarUrl = store.getState().StellarUrl;
  StellarUrlTest = store.getState().StellarUrlTest;
});
export async function addArt(tokenModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = tokenModel;
    const res = await axios.post(
      niftronTokenLambda + "/tokens/mint/digitalart",
      postBody,
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (res === null) {
      return null;
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function addCertificate(tokenModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = tokenModel;
    const res = await axios.post(
      niftronTokenLambda + "/tokens/mint/certificate",
      postBody,
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (res === null) {
      return null;
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function addBadge(tokenModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = tokenModel;
    const res = await axios.post(
      niftronTokenLambda + "/tokens/mint/badge",
      postBody,
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (res === null) {
      return null;
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function addRecord(tokenModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = tokenModel;
    const res = await axios.post(
      niftronTokenLambda + "/tokens/mint/record",
      postBody,
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (res === null) {
      return null;
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function addTicket(tokenModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = tokenModel;
    const res = await axios.post(
      niftronTokenLambda + "/tokens/mint/ticket",
      postBody,
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (res === null) {
      return null;
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function addDomain(tokenModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = tokenModel;
    const res = await axios.post(
      niftronTokenLambda + "/tokens/mint/domain",
      postBody,
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    if (res === null) {
      return null;
    }
    return res.status;
  } catch (err) {
    return null;
  }
}
export async function searchToken(key, type, limit, page) {
  try {
    let query = "";
    if (key != "") {
      query += `key=${key}&`;
    }
    if (type != "") {
      query += `keyType=${type}&`;
    }

    if (limit > 0 && page > 0) {
      query += `limit=${limit}&page=${page}`;
    }

    const res = await axios.get(`${niftronTokenLambda}/tokens?${query}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res === null) {
      return null;
    }
    return res.data;
  } catch (err) {
    return null;
  }
}
export async function getTokenById(id) {
  try {
    const res = await axios.get(`${NiftronAPI}/tokens/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res === null) {
      return null;
    }
    return res.data;
  } catch (err) {
    return null;
  }
}
export async function getTokenByIdList(idList) {
  try {
    const res = await axios.post(`${NiftronAPI}/tokens/getData`, idList, {
      // const res = await axios.post(`http://localhost:3000/api/v1/prod/getData`, idList, {

      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res === null) {
      return null;
    }
    return res.data;
  } catch (err) {
    return null;
  }
}
export async function getTokenBalanceFromStellar(publicKey) {
  try {
    let assets = [];
    let server = new Server(StellarUrl);
    let sourceAccount;
    try {
      sourceAccount = await server.loadAccount(publicKey);
    } catch (err) {
      // //console.log(err)
      try {
        server = new Server(StellarUrlTest);
        sourceAccount = await server.loadAccount(publicKey);
      } catch (err2) {
        // //console.log(err2)
        return null;
      }
    }
    sourceAccount.balances.forEach(function (balance) {
      // @ts-ignore
      // ////////console.log('Asset_code:', balance.asset_code, ', Balance:', balance.balance);
      // let bal = parseFloat(balance.balance)
      // let lim = parseFloat(balance.limit)
      if (
        balance.asset_type != "native" &&
        balance.asset_issuer != NiftronIssuerPublicKey &&
        balance.balance > 0.0
      ) {
        assets.push({
          assetCode: balance.asset_code,
          balance: balance.balance,
          limit: balance.limit,
          issuer: balance.asset_issuer,
        });
      }
    });
    // assets.pop();
    ////////console.log(assets)
    return assets;

    // return account.balances
  } catch (err) {
    //console.log(err)
    return null;
  }
}
export async function getNonStellarTokensByUser(publicKey) {
  try {
    const res = await axios.get(`${NiftronAPI}/tokens/${publicKey}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res === null) {
      return null;
    }
    return res.data;
  } catch (err) {
    return null;
  }
}

export async function getIPFSData(ipfsHash) {
  try {
    let res = await axios.get(`https://cloudflare-ipfs.com/ipfs/${ipfsHash}`);

    if (res === null) {
       res = await axios.get(`https://ipfs.io/ipfs/${ipfsHash}`);
      if (res === null) {
        res = await axios.get(`https://cf-ipfs.com/ipfs/${ipfsHash}`);
        if (res === null) {
          throw Error("Failed to add data to IPFS");
        }
      }
    }
    return res.data;
  } catch (er) {
    throw er;
  }
}
