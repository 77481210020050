export const initialstate = {
  fields: [],
  products: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_FIELD":
      return {
        ...state,
        fields: [...state.fields, action.item],
      };
    case "ADD_PRODUCT":
      return {
        ...state,
        products: [...state.products, action.item],
      };
    case "REMOVE_FIELD":
      const index = state.fields.findIndex(
        (fieldsItem) => fieldsItem.name === action.id
      );

      let newFields = [...state.fields];
      if (index >= 0) {
        newFields.splice(index, 1);
      } else {
        console.warn(`Cannot remove the requested field (id: ${action.id}`);
      }
      return {
        ...state,
        fields: newFields,
      };

    case "REMOVE_PRODUCT":
      const key = state.products.findIndex(
        (productsItem) => productsItem.id === action.id
      );
      let newProducts = [...state.products];
      if (key >= 0) {
        newProducts.splice(key, 1);
      } else {
        console.warn(`Cannot remove the requested products (id: ${action.id}`);
      }
      return {
        ...state,
        products: newProducts,
      };

    case "EDIT_PRODUCT":
      const updatedProduct = action.payload;

      const updatedProducts = state.products.map((product) => {
        if (product.productId === updatedProduct.productId) {
          return updatedProduct;
        }

        return product;
      });

      return {
        ...state,
        products: updatedProducts,
      };

    case "GET_PRODUCT":
      const productId = action.id;
      const productWithId = state.products.map((product) => {
        if (product.productId === productId) {
          return product;
        }
      });

      return {
        ...state,
        product: productWithId,
      };

    case "CLEAR_FIELDS":
      return {
        ...state,
        fields: [],
      };

    case "CLEAR_PRODUCTS":
      return {
        ...state,
        products: [],
      };
    default:
      return state;
  }
};

export default reducer;
