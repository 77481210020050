import { useState } from "react";
import styled from "styled-components";
import {Paragraph, PrimarySubText} from "../Typography/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  secondaryButton: {
    color: "#6687f18d",
  },
}));

export default function CopyPasteComponent({ value, label }) {
  // eslint-disable-next-line
  const [copySuccess, setCopySuccess] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const copyToClipBoard = async (val) => {
    try {
      await navigator.clipboard.writeText(val);
      setCopySuccess("Secret Key Copied");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

   return (
      <OuterContainer>
        <Paragraph>{label}</Paragraph>
        <Container>
          <PrimarySubText>{value.length > 20 ? `${value.substring(0, 20)} ...` : value}</PrimarySubText>
          <IconButton
            onClick={() => {
              copyToClipBoard(value);
              enqueueSnackbar(`${label} Copied`, {
                variant: "info",
              });
            }}
          >
            <ContentCopyIcon className={classes.secondaryButton} />
          </IconButton>
        </Container>
      </OuterContainer>
    );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0.5rem 1rem;
`;

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
  gap: 0.5rem;
  min-width: 25rem;
  max-width: 28vw;
  @media (max-width: 768px) {
    min-width: 85vw;
    max-width: 85vw;
  }
`;

const Text = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
