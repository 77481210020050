import store from "../redux/reducers";
import jwt from "jsonwebtoken";
import axios from "axios";
import {NiftronAPI,niftronUserLambda} from "../variables/constants";
export function getUserSession() {
  if (localStorage.getItem("niftoken") !== null) {
    // jwt.decode(localStorage.getItem("token"))
    const decodedToken = jwt.decode(localStorage.getItem("niftoken"));
    if (decodedToken === null) {
      return null;
    } else {
      // ////////console.log(decodedToken)
      return decodedToken;
    }
  }
}

export async function SearchOrGetAccounts(Key) {
  try {
    let token;
    if (localStorage.getItem("niftoken") != null) {
      token = localStorage.getItem("niftoken");
    }

    const query = Key === "" ? "?key=" + Key : "";
    const res = await axios.get(NiftronAPI + "/users/search" + query, {
      headers: {
        'Authorization': "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res != null) {
      if (res.status === 200) {
        return res.data.data;
      } else {
        return res.data.data;
      }
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}