import React from "react";
import { IconButton } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function BackToCreate() {
  const history = useHistory();
  const location = useLocation();

  const handleBack = (e) => {
    e.preventDefault();
    if (location.pathname === "/create") {
      history.push("/create");
    } else if (location.pathname === "/vault") {
      window.location.reload(false);
    } else if (location.pathname === "/create/digital" || "/create/physical") {
      history.push("/create");
    }
  };

  return (
    <IconButton onClick={handleBack}>
      <ArrowBackIcon fontSize="small" color="primary" />
    </IconButton>
  );
}
